import React from "react";

type EnglishProps = {};

const English: React.FC<EnglishProps> = ({}) => {
  return (
    <div className="teacher-portal__student--row teacher-portal__student--row--english">
      <div
        className="teacher-portal__student--stat-container"
        onClick={() => {
          // showNotesModal();
        }}
      >
        <div className="teacher-portal__student--stat-title">Student Notes</div>
        <div className="teacher-portal__student--stat-box teacher-portal__student--stat-box--notes">
          <div className="teacher-portal__student--stat-box--notes-list">
            <div className="teacher-portal__student--stat-box--notes-row">
              List
            </div>
          </div>
          <div className="teacher-portal__student--stat-box--change-level english-bg">
            Add
          </div>
        </div>
      </div>

      <div className="teacher-portal__student--stat-container">
        <div
          // onClick={() => showQuickAssignModal(activeClassroom, student)}
          className="teacher-portal__student--stat-title teacher-portal__student--stat-title--assign english-bg"
        >
          Assign +
        </div>
        <div className="teacher-portal__student--stat-box">
          <div
            className="teacher-portal__student--stat-box--row teacher-portal__student--stat-box--row-assigned"
            // onClick={() =>
            //   showToDoModal(
            //     studentAssignment,
            //     studentAssignment?.assignments.filter(
            //       (assignment) =>
            //         new Date(assignment.due_date).valueOf() >
            //           new Date(Date.now()).valueOf() &&
            //         !assignment.relevant_data
            //     ).length
            //   )
            // }
          >
            <div className="teacher-portal__student--stat-box--to-do-text">
              To Do
            </div>
            {/* <div className="teacher-portal__student--stat-box--to-do-value">
              {
                studentAssignment?.assignments.filter(
                  (assignment) =>
                    new Date(assignment.due_date).valueOf() >
                      new Date(Date.now()).valueOf() &&
                    !assignment.relevant_data
                ).length
              }
            </div> */}
          </div>
          <div
            className="teacher-portal__student--stat-box--row teacher-portal__student--stat-box--row-assigned"
            // onClick={() =>
            //   showOverdueModal(
            //     studentAssignment,
            //     studentAssignment?.assignments.filter(
            //       (assignment) =>
            //         new Date(assignment.due_date).valueOf() <
            //           new Date(Date.now()).valueOf() &&
            //         !assignment.relevant_data
            //     ).length
            //   )
            // }
          >
            <div className="teacher-portal__student--stat-box--overdue-text">
              Overdue
            </div>
            <div className="teacher-portal__student--stat-box--overdue-value">
              {/* {
                studentAssignment?.assignments.filter(
                  (assignment) =>
                    new Date(assignment.due_date).valueOf() <
                      new Date(Date.now()).valueOf() &&
                    !assignment.relevant_data
                ).length
              } */}
            </div>
          </div>
          <div
            className="teacher-portal__student--stat-box--row teacher-portal__student--stat-box--row-assigned"
            // onClick={() => {
            //   showCompletedModal(
            //     studentAssignment,
            //     studentAssignment?.assignments.filter(
            //       (assignment) => assignment.relevant_data
            //     ).length
            //   );
            // }}
          >
            <div className="teacher-portal__student--stat-box--completed-text">
              Completed
            </div>
            <div className="teacher-portal__student--stat-box--completed-value">
              {
                // studentAssignment?.assignments.filter(
                //   (assignment) => assignment.relevant_data
                // ).length
              }
            </div>
          </div>
        </div>
      </div>

      <div
        className="teacher-portal__student--stat-container"
        onClick={() => {
          // showNotesModal();
        }}
      >
        <div className="teacher-portal__student--stat-title">Student Notes</div>
        <div className="teacher-portal__student--stat-box">
          <div className="teacher-portal__student--stat-box--notes-list">
            <div className="teacher-portal__student--stat-box--notes-row">
              List
            </div>
          </div>
          <div className="teacher-portal__student--stat-box--change-level english-bg">
            Add
          </div>
        </div>
      </div>

      <div
        className="teacher-portal__student--stat-container"
        onClick={() => {
          // showNotesModal();
        }}
      >
        <div className="teacher-portal__student--stat-title">Student Notes</div>
        <div className="teacher-portal__student--stat-box">
          <div className="teacher-portal__student--stat-box--notes-list">
            <div className="teacher-portal__student--stat-box--notes-row">
              List
            </div>
          </div>
          <div className="teacher-portal__student--stat-box--change-level english-bg">
            Add
          </div>
        </div>
      </div>
    </div>
  );
};

export default English;
