import React, { useEffect, useState } from "react";
import { useDisplayData } from "../Hooks/useDisplayData";
import { useUserData } from "../Hooks/useUserData";
import { Classrooms, StudentData, TeacherData } from "../Models";
import {
  ConvertGradeToNumberOrString,
  decrypt,
  generateID,
  GetBaseURL,
} from "../Utils";
import ButtonsContainer from "../Components/Partials/ButtonsContainer";
import Dashboard from "../Components/Dashboard";
import Header from "../Components/Header";
import LessonPlans from "../Components/LessonPlans";
import Messages from "../Components/Messages";

import Modal from "../Components/Partials/Modal";
import ReportCards from "../Components/ReportCards";
import AssignmentHub from "../Components/AssignmentHub";

type NavBarItem =
  | "Dashboard"
  | "Assignment Hub"
  | "Messages"
  | "Report Cards"
  | "Lesson Plans"
  | "Apps";

const TeacherPortal: React.FC = () => {
  const { displayData } = useDisplayData();
  const [activeTab, setActiveTab] = useState<NavBarItem>("Dashboard");

  return (
    <div className="teacher-portal">
      {displayData.showModal && <Modal />}
      <Header />

      <div
        className="teacher-portal__nav-bar"
        style={
          displayData.settingAssignment ? { pointerEvents: "none" } : undefined
        }
      >
        <span
          className={
            activeTab === "Dashboard"
              ? "teacher-portal__nav-bar--item--selected teacher-portal__nav-bar--item"
              : "teacher-portal__nav-bar--item"
          }
          onClick={() => setActiveTab("Dashboard")}
        >
          Dashboard
        </span>
        <span
          className={
            activeTab === "Assignment Hub"
              ? "teacher-portal__nav-bar--item--selected teacher-portal__nav-bar--item"
              : "teacher-portal__nav-bar--item"
          }
          onClick={() => setActiveTab("Assignment Hub")}
        >
          Assignment Hub
        </span>
        <span
          className={
            activeTab === "Messages"
              ? "teacher-portal__nav-bar--item--selected teacher-portal__nav-bar--item"
              : "teacher-portal__nav-bar--item"
          }
          onClick={() => setActiveTab("Messages")}
        >
          Messages
        </span>
        <span
          className={
            activeTab === "Report Cards"
              ? "teacher-portal__nav-bar--item--selected teacher-portal__nav-bar--item"
              : "teacher-portal__nav-bar--item"
          }
          onClick={() => setActiveTab("Report Cards")}
        >
          Report Cards
        </span>
        <span
          className={
            activeTab === "Lesson Plans"
              ? "teacher-portal__nav-bar--item--selected teacher-portal__nav-bar--item"
              : "teacher-portal__nav-bar--item"
          }
          onClick={() => setActiveTab("Lesson Plans")}
        >
          Lesson Plans
        </span>
        <span
          className={
            activeTab === "Apps"
              ? "teacher-portal__nav-bar--item--selected teacher-portal__nav-bar--item"
              : "teacher-portal__nav-bar--item"
          }
          onClick={() => setActiveTab("Apps")}
        >
          Apps
        </span>
      </div>
      {activeTab === "Dashboard" && <Dashboard />}
      {activeTab === "Assignment Hub" && <AssignmentHub />}
      {activeTab === "Messages" && <Messages />}
      {activeTab === "Report Cards" && <ReportCards />}
      {activeTab === "Lesson Plans" && <LessonPlans />}
      {activeTab === "Apps" && <ButtonsContainer />}
    </div>
  );
};

export default TeacherPortal;
