import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import CompletedAssignmentDetails from "../Components/Partials/CompletedAssignmentDetails";
import { useDisplayData } from "../Hooks/useDisplayData";
import { StudentAssignment } from "../Models";
import { GetBaseURL } from "../Utils";

const Markup: React.FC = () => {
  const { setActiveClassroomInContext, setActiveAssignmentInContext } =
    useDisplayData();
  const params = useParams();
  const [assignment, setAssignment] = useState<StudentAssignment | undefined>();
  const [failed, setFailed] = useState(false);

  const fetchAssignment = async () => {
    const req = await fetch(
      `${GetBaseURL()}/api/classrooms/fetchAssignment?key=${
        process.env.REACT_APP_PERSONAL_AUTH_KEY
      }`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          classroom_id: params.assignmentInfo?.split("-")[0].slice(5),
          student_id: params.assignmentInfo?.split("-")[1].slice(7),
          story_id: params.assignmentInfo?.split("-")[2].slice(5),
        }),
      }
    );
    const res = await req.json();
    setAssignment(res.studentAssignment);
    if (req.status !== 200) {
      setFailed(true);
    } else {
      setActiveAssignmentInContext(res.assignment);
      setActiveClassroomInContext(res.classroom);
    }
  };

  useEffect(() => {
    fetchAssignment();
  }, [params]);

  return (
    <div>
      {assignment ? (
        <CompletedAssignmentDetails
          view={true}
          assignment={assignment}
          setExpandedAssignments={() => {}}
        />
      ) : failed ? (
        <div>Failed</div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default Markup;
