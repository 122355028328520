import React from "react";

const MoonRockHeader: React.FC = () => {
  return (
    <div className="moonrock-header">
      <div className="header__logo">
        <img
          src="https://moonrock-images.s3.us-east-2.amazonaws.com/moonrock-logo.png"
          alt="Project MoonRock"
          className="moonrock-header__logo"
        />
      </div>
      <div className="header__user">
        <div className="header__dropdown-arrow">
          <img
            src="https://moonrock-images.s3.us-east-2.amazonaws.com/ui_golden_x.png"
            alt="Close"
            className="moonrock-header__close"
          />
        </div>
      </div>
    </div>
  );
};
export default MoonRockHeader;
