import React, { useState, useEffect } from "react";
import { Phonics, Story, Words } from "../Models/Stories";
import { GetBaseURL } from "../Utils";

export type StoryDataType = {
  stories: Story[];
  words: Words[];
  phonics: Phonics[];
  currentStoryID: string;
  update?: boolean;
  filter: "category" | "level";
};
export interface StoryContextProps {
  storyData: StoryDataType;
  setStoryData: (storyData: any) => void;
}

const StoryContext = React.createContext({} as StoryContextProps);

const StoryContextProvider = (props: any) => {
  const [storyData, setStoryData] = useState<StoryDataType>({
    stories: [],
    words: [],
    phonics: [],
    currentStoryID: "",
    update: true,
    filter: "category",
  });

  const fetchAllStories = async () => {
    const request = await fetch(
      `${GetBaseURL()}/api/stories?key=${
        process.env.REACT_APP_PERSONAL_AUTH_KEY
      }`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    );
    const response = await request.json();
    let foundStories: Story[] = response.stories;

    setStoryData((prev: StoryDataType) => ({
      ...prev,
      stories: foundStories,
    }));
  };

  const fetchAllWords = async () => {
    const request = await fetch(
      `${GetBaseURL()}/api/words?key=${
        process.env.REACT_APP_PERSONAL_AUTH_KEY
      }`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    );
    const response = await request.json();
    setStoryData((prev: StoryDataType) => ({ ...prev, words: response.words }));
  };

  const fetchAllPhonics = async () => {
    const request = await fetch(
      `${GetBaseURL()}/api/words/getPhonics?key=${
        process.env.REACT_APP_PERSONAL_AUTH_KEY
      }`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    );
    const response = await request.json();
    setStoryData((prev: StoryDataType) => ({
      ...prev,
      phonics: response.phonics,
    }));
  };

  useEffect(() => {
    if (storyData.update) {
      fetchAllStories();

      fetchAllWords();

      fetchAllPhonics();

      setStoryData((prev: StoryDataType) => ({
        ...prev,
        update: false,
      }));
    }
  }, [storyData]);

  const providerValue: StoryContextProps = {
    storyData,
    setStoryData,
  };

  return (
    <StoryContext.Provider value={providerValue}>
      {props.children}
    </StoryContext.Provider>
  );
};

export { StoryContext, StoryContextProvider };
