import React, { FormEvent, useRef, useState } from "react";
import { useUserData } from "../../Hooks/useUserData";
import { GetBaseURL, ValidateEmail } from "../../Utils";

import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import { useDisplayData } from "../../Hooks/useDisplayData";

import NewEncryption from "../../Utils/NewEncryption";
import Notes from "./Modals/Notes";
import TrackedWords from "./Modals/TrackedWords";
import ChangeReadingLevel from "./Modals/ChangeReadingLevel";
import Overdue from "./Modals/Overdue";
import ToDo from "./Modals/ToDo";
import Completed from "./Modals/Completed";
import QuickAssign from "./Modals/QuickAssign";
import AddStudent from "./Modals/AddStudent";
import AddClassroom from "./Modals/AddClassroom";
import RegistrationForm from "./Modals/RegistrationForm";

type ModalProps = {};

export type QuickAssignStory = { id: string | undefined; title: string };

const Modal: React.FC<ModalProps> = ({}) => {
  const { setUser } = useUserData();
  const { displayData, showLoginModal, showRegisterModal, hideModal } =
    useDisplayData();

  const usernameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const [userRole, setUserRole] = useState<"parent" | "teacher" | null>(null);
  const [emailLogin, setEmailLogin] = useState(false);

  const [error, setError] = useState({
    isActive: false,
    type: "error",
    message: "",
  });

  const googleRegister = async (response: any) => {
    if (response.profileObj) {
      const request = await fetch(
        `${GetBaseURL()}/api/users/register?key=${
          process.env.REACT_APP_PERSONAL_AUTH_KEY
        }`,
        {
          method: "POST",
          body: JSON.stringify({
            account_type: userRole,
            username: response.profileObj.email,
            email: response.profileObj.email,
            name: response.profileObj.name,
            device:
              window.innerWidth <= 600
                ? "mobile"
                : window.innerWidth <= 1024
                ? "tablet"
                : "desktop",
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const user = await request.json();
      if (request.status === 200) {
        setUser(user._id);
      } else if (request.status === 500) {
        googleLogin(response);
      } else {
        setError({
          isActive: true,
          message: user.message,
          type: "error",
        });
      }
    } else {
      console.log(response);
      setError({
        isActive: true,
        message: "Google authorization error. Please try again.",
        type: "error",
      });
    }
  };
  const facebookRegister = async (response: any) => {
    console.log(response);
    if (response.email) {
      const request = await fetch(
        `${GetBaseURL()}/api/users/register?key=${
          process.env.REACT_APP_PERSONAL_AUTH_KEY
        }`,
        {
          method: "POST",
          body: JSON.stringify({
            account_type: userRole,
            username: response.email,
            email: response.email,
            name: response.name,
            device:
              window.innerWidth <= 600
                ? "mobile"
                : window.innerWidth <= 1024
                ? "tablet"
                : "desktop",
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const user = await request.json();
      if (request.status === 200) {
        setUser(user._id);
      } else if (request.status === 500) {
        facebookLogin(response);
      } else {
        setError({
          isActive: true,
          message: user.errorMessage,
          type: "error",
        });
      }
    } else {
      setError({
        isActive: true,
        message: "Facebook authorization error. Please try again.",
        type: "error",
      });
    }
  };

  const googleLogin = async (response: any) => {
    if (response.profileObj) {
      const request = await fetch(
        `${GetBaseURL()}/api/users/login?key=${
          process.env.REACT_APP_PERSONAL_AUTH_KEY
        }`,
        {
          method: "POST",
          body: JSON.stringify({
            username: response.profileObj.email,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (request.status === 200) {
        const foundUser = await request.json();
        setUser(foundUser.user._id);
        hideModal();
      } else {
        setError({
          isActive: true,
          message:
            "You do not have an account. Please use the registration form instead.",
          type: "error",
        });
      }
    } else {
      setError({
        isActive: true,
        message: "Google authorization error. Please try again.",
        type: "error",
      });
    }
  };

  const facebookLogin = async (response: any) => {
    console.log(response);
    if (response.email) {
      const request = await fetch(
        `${GetBaseURL()}/api/users/login?key=${
          process.env.REACT_APP_PERSONAL_AUTH_KEY
        }`,
        {
          method: "POST",
          body: JSON.stringify({
            username: response.email,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (request.status === 200) {
        const foundUser = await request.json();
        setUser(foundUser.user._id);
        hideModal();
      } else {
        setError({
          isActive: true,
          message:
            "You do not have an account. Please use the registration form instead.",
          type: "error",
        });
      }
    } else {
      setError({
        isActive: true,
        message: "Facebook authorization error. Please try again.",
        type: "error",
      });
    }
  };

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault();
    if (usernameRef.current && passwordRef.current) {
      if (
        usernameRef.current.value === "" ||
        passwordRef.current.value === ""
      ) {
        setError({
          isActive: true,
          type: "error",
          message: "Please enter a username AND a password.",
        });
      } else if (!ValidateEmail(usernameRef.current.value)) {
        setError({
          isActive: true,
          type: "error",
          message: "Please enter a valid email.",
        });
      } else {
        const request = await fetch(
          `${GetBaseURL()}/api/users/login?key=${
            process.env.REACT_APP_PERSONAL_AUTH_KEY
          }`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              username: usernameRef.current.value,
              password: NewEncryption.encrypt(passwordRef.current.value),
            }),
          }
        );
        const response = await request.json();
        if (request.status === 200) {
          // if (response.user.user_data.verified) {
          setUser(response.user._id);
          setError({
            isActive: true,
            type: "success",
            message: response.message,
          });
          hideModal();

          // } else {
          //   setError({
          //     isActive: true,
          //     type: "error",
          //     message:
          //       "You need to verify your email to continue. Please check your email.",
          //   });
          // }
        } else {
          setError({
            isActive: true,
            type: "error",
            message:
              "Sorry, that user does not exist. Please register instead.",
          });
        }
      }
    }
  };

  return (
    <section className="modal">
      {
        <span
          className={
            displayData.register && userRole && !emailLogin
              ? "modal__close  modal__close--white"
              : "modal__close"
          }
          onClick={() => hideModal()}
        >
          x
        </span>
      }
      {error.isActive && error.type === "error" && (
        <span className="modal__error">{error.message}</span>
      )}
      {error.isActive && error.type === "success" && (
        <span className="modal__success">{error.message}</span>
      )}
      {displayData.login && !emailLogin && (
        <div className="modal__login">
          <div className="modal__prompt">Log in for access to stories</div>
          <div className="modal__social-sign-in-wrapper">
            <div className="modal__facebook-button-fix">
              <FacebookLogin
                appId={`${process.env.REACT_APP_FB_ID}`}
                autoLoad={false}
                fields="name,email,picture"
                scope="email"
                callback={facebookLogin}
                cssClass="modal__facebook-login"
                textButton="Sign in with Facebook"
                icon="fa-facebook"
                disableMobileRedirect={true}
              />
            </div>
            <GoogleLogin
              clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}.apps.googleusercontent.com`}
              render={(renderProps) => (
                <button
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  className="modal__google-login"
                >
                  <i className="fab fa-google"></i>
                  Sign in with Google
                </button>
              )}
              buttonText="Login"
              onSuccess={googleLogin}
              onFailure={googleLogin}
              cookiePolicy={"single_host_origin"}
            />
            <button
              className="modal__email-button"
              onClick={() => setEmailLogin(true)}
            >
              Sign in with email instead
            </button>
          </div>
          <div className="modal__instead">
            Don't have an account?{" "}
            <span
              className="modal__instead--link"
              onClick={() => {
                showRegisterModal();
              }}
            >
              Register
            </span>{" "}
            instead!
          </div>
        </div>
      )}
      {displayData.login && emailLogin && (
        <form onSubmit={handleLogin} className="modal__form">
          <span
            className="modal__go-back"
            style={{ cursor: "pointer", color: "#e3522a" }}
            onClick={() => setEmailLogin(false)}
          >
            {"Go back"}
          </span>
          <input
            className="modal__input"
            type="text"
            placeholder="Email"
            ref={usernameRef}
          />
          <input
            className="modal__input"
            type="password"
            placeholder="Password"
            ref={passwordRef}
          />
          <input className="modal__submit" type="submit" value="Login"></input>
          <div className="modal__instead">
            Don't have an account?{" "}
            <span
              className="modal__instead--link"
              onClick={() => {
                showRegisterModal();
              }}
            >
              Register
            </span>{" "}
            instead!
          </div>
        </form>
      )}
      {displayData.register && !userRole && (
        <div className="modal__register">
          <div className="modal__register-title">I am a ...</div>

          <div className="modal__button-wrapper">
            <button
              className="modal__button modal__button--parent"
              onClick={() => setUserRole("parent")}
            >
              {/* Parent */}
            </button>
            <button
              className="modal__button modal__button--educator"
              onClick={() => setUserRole("teacher")}
              // style={{ color: "gray", pointerEvents: "none" }}
            >
              {/* Educator (Coming Soon!) */}
            </button>
          </div>
        </div>
      )}
      {displayData.register && userRole && !emailLogin && (
        <div className="modal__register">
          <img
            className="modal__modal-img"
            src={`${process.env.REACT_APP_AWS_BUCKET_URL}/modal-img.png`}
            alt={"No Image"}
          />
          {userRole === "parent" ? (
            <>
              <div className="modal__user-role">
                <span>Have your child reading in record time!</span>
              </div>
              <div className="modal__social-sign-in-wrapper">
                <div className="modal__facebook-button-fix">
                  <FacebookLogin
                    appId={`${process.env.REACT_APP_FB_ID}`}
                    autoLoad={false}
                    fields="name,email,picture"
                    scope="email"
                    callback={facebookRegister}
                    cssClass="modal__facebook-login"
                    textButton="Sign up with Facebook"
                    icon="fa-facebook"
                    disableMobileRedirect={true}
                  />
                </div>
                <GoogleLogin
                  clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}.apps.googleusercontent.com`}
                  render={(renderProps) => (
                    <button
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                      className="modal__google-login"
                    >
                      <i className="fab fa-google"></i>
                      Sign up with Google
                    </button>
                  )}
                  buttonText="Login"
                  onSuccess={googleRegister}
                  onFailure={googleRegister}
                  cookiePolicy={"single_host_origin"}
                />
                <button
                  className="modal__email-button"
                  onClick={() => setEmailLogin(true)}
                >
                  Sign up with email instead
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="modal__user-role">
                <span>Have your students reading in record time!</span>
              </div>
              <div className="modal__social-sign-in-wrapper">
                <div className="modal__facebook-button-fix">
                  <FacebookLogin
                    appId={`${process.env.REACT_APP_FB_ID}`}
                    autoLoad={false}
                    fields="name,email,picture"
                    scope="email"
                    callback={facebookRegister}
                    cssClass="modal__facebook-login"
                    textButton="Sign up with Facebook"
                    icon="fa-facebook"
                    disableMobileRedirect={true}
                  />
                </div>
                <GoogleLogin
                  clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}.apps.googleusercontent.com`}
                  render={(renderProps) => (
                    <button
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                      className="modal__google-login"
                    >
                      <i className="fab fa-google"></i>
                      Sign up with Google
                    </button>
                  )}
                  buttonText="Login"
                  onSuccess={googleRegister}
                  onFailure={googleRegister}
                  cookiePolicy={"single_host_origin"}
                />
                <button
                  className="modal__email-button"
                  onClick={() => setEmailLogin(true)}
                >
                  Sign up with email instead
                </button>
              </div>
            </>
          )}
          <div className="modal__instead">
            Already have an account?{" "}
            <span
              className="modal__instead--link"
              onClick={() => {
                showLoginModal();
              }}
            >
              Log in
            </span>{" "}
            instead!
          </div>
        </div>
      )}
      {displayData.register && userRole && emailLogin && (
        <RegistrationForm
          usernameRef={usernameRef}
          passwordRef={passwordRef}
          setError={setError}
          userRole={userRole}
          setEmailLogin={setEmailLogin}
        />
      )}
      {displayData.addClassroom && <AddClassroom setError={setError} />}
      {displayData.addStudent && <AddStudent setError={setError} />}
      {displayData.quickAssign && <QuickAssign setError={setError} />}
      {displayData.showCompleted && <Completed />}
      {displayData.showToDo && <ToDo />}
      {displayData.showOverdue && <Overdue />}
      {displayData.changeReadingLevel && <ChangeReadingLevel />}
      {displayData.trackedWords && <TrackedWords />}
      {displayData.notes && <Notes />}
    </section>
  );
};

export default Modal;
