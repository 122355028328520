import React from "react";
import { MistakesType } from "../../Models";
import { ManualMarkupState } from "./CompletedAssignmentDetails";

export type MistakeOptionsDropdownProps = {
  manualMarkup: ManualMarkupState;
  setManualMarkup: (value: React.SetStateAction<ManualMarkupState>) => void;
  setMistakeOptions: (value: React.SetStateAction<any>) => void;
  pageIndex: number;
  lineIndex: number;
  wordIndex: number;
  word: string;
};

const MistakeOptionsDropdown: React.FC<MistakeOptionsDropdownProps> = ({
  manualMarkup,
  setManualMarkup,
  setMistakeOptions,
  pageIndex,
  lineIndex,
  wordIndex,
  word,
}) => {
  return (
    <div
      className="modal__completed--mistakes-dropdown"
      style={{
        left: `${4.31429 * word.length - 84}px`,
      }}
    >
      {[
        "Mispronunciation",
        "Repetition",
        "Omission",
        "Self-Correction",
        "Other",
      ].map((mistake) => (
        <span
          className="modal__completed--mistake-option"
          style={
            manualMarkup.markedUpWords.some(
              (markedUpWord) =>
                markedUpWord.page_index === pageIndex &&
                markedUpWord.line_index === lineIndex &&
                markedUpWord.word_index === wordIndex &&
                markedUpWord.word === word &&
                markedUpWord.mistake === mistake
            )
              ? {
                  background:
                    mistake === "Mispronunciation"
                      ? "#9e62e2"
                      : mistake === "Repetition"
                      ? "#0093e7"
                      : mistake === "Omission"
                      ? "#fb6557"
                      : mistake === "Self-Correction"
                      ? "#ff982a"
                      : "#209a5d",
                  border: "1px solid",
                  borderColor:
                    mistake === "Mispronunciation"
                      ? "#9e62e2"
                      : mistake === "Repetition"
                      ? "#0093e7"
                      : mistake === "Omission"
                      ? "#fb6557"
                      : mistake === "Self-Correction"
                      ? "#ff982a"
                      : "#209a5d",

                  color: "white",
                  fontFamily: "Fellix-SemiBold",
                }
              : {
                  border: "1px solid",
                  borderColor:
                    mistake === "Mispronunciation"
                      ? "#9e62e2"
                      : mistake === "Repetition"
                      ? "#0093e7"
                      : mistake === "Omission"
                      ? "#fb6557"
                      : mistake === "Self-Correction"
                      ? "#ff982a"
                      : "#209a5d",

                  color:
                    mistake === "Mispronunciation"
                      ? "#9e62e2"
                      : mistake === "Repetition"
                      ? "#0093e7"
                      : mistake === "Omission"
                      ? "#fb6557"
                      : mistake === "Self-Correction"
                      ? "#ff982a"
                      : "#209a5d",
                  fontFamily: "Fellix-SemiBold",
                }
          }
          onClick={() => {
            setManualMarkup((prev: ManualMarkupState) => ({
              ...prev,
              markedUpWords: prev.markedUpWords.some(
                (markedUpWord) =>
                  markedUpWord.page_index === pageIndex &&
                  markedUpWord.line_index === lineIndex &&
                  markedUpWord.word_index === wordIndex &&
                  markedUpWord.word === word
              )
                ? prev.markedUpWords.some(
                    (markedUpWord) =>
                      markedUpWord.page_index === pageIndex &&
                      markedUpWord.line_index === lineIndex &&
                      markedUpWord.word_index === wordIndex &&
                      markedUpWord.word === word &&
                      markedUpWord.mistake === mistake
                  )
                  ? prev.markedUpWords.filter(
                      (markedUpWord) =>
                        markedUpWord.page_index !== pageIndex ||
                        markedUpWord.line_index !== lineIndex ||
                        markedUpWord.word_index !== wordIndex ||
                        markedUpWord.word !== word
                    )
                  : [
                      ...prev.markedUpWords.filter(
                        (markedUpWord) =>
                          markedUpWord.page_index !== pageIndex ||
                          markedUpWord.line_index !== lineIndex ||
                          markedUpWord.word_index !== wordIndex ||
                          markedUpWord.word !== word
                      ),
                      {
                        word,
                        word_index: wordIndex,
                        page_index: pageIndex,
                        line_index: lineIndex,
                        mistake: mistake as MistakesType,
                      },
                    ]
                : [
                    ...prev.markedUpWords,
                    {
                      word,
                      word_index: wordIndex,
                      page_index: pageIndex,
                      line_index: lineIndex,
                      mistake: mistake as MistakesType,
                    },
                  ],
            }));
            setMistakeOptions((prev: any) => ({
              ...prev,
              show: false,
            }));
          }}
        >
          {mistake}
        </span>
      ))}
    </div>
  );
};

export default MistakeOptionsDropdown;
