import React, { useEffect, useLayoutEffect, useState } from "react";
import { useStories } from "../../Hooks/useStories";
import { Story } from "../../Models";
import { QuickAssignStory } from "./Modal";

type StoryRowProps = {
  story_id?: string;
  highlight: boolean;
  dueDate?: Date;
  setQuickAssignStory?: (
    value: React.SetStateAction<QuickAssignStory | null>
  ) => void;
  quickAssignStory?: QuickAssignStory | null;
};

const StoryRow: React.FC<StoryRowProps> = ({
  story_id,
  highlight,
  dueDate,
  setQuickAssignStory,
  quickAssignStory,
}) => {
  const { storyData } = useStories();
  const [story, setStory] = useState<Story | null>(null);
  useLayoutEffect(() => {
    if (story_id && storyData && storyData.stories) {
      const foundStory = storyData.stories.find(
        (indexedStory) => indexedStory._id === story_id
      );
      if (foundStory) {
        setStory(foundStory);
      }
    }
  }, [storyData, story_id]);

  return story ? (
    <div
      className={`story-row--loaded ${highlight ? " story-row--selected" : ""}`}
      onClick={() => {
        if (setQuickAssignStory && story) {
          if (quickAssignStory && quickAssignStory.id === story_id) {
            setQuickAssignStory(null);
          } else {
            setQuickAssignStory({ id: story_id, title: story.title });
          }
        }
      }}
    >
      <div className="story-row--image-container">
        <img
          className="story-row--image"
          src={`${process.env.REACT_APP_AWS_BUCKET_URL}/${story.cover}`}
        />
      </div>
      <div className="story-row--title">{story.title}</div>
      <div className="story-row--level">{story.level}</div>

      <div className="story-row--word-count">
        {
          Array.from(
            new Set(
              story.pages
                .map((page) => {
                  return page.page_text
                    .replace(/\r/g, "")
                    .split(/\n/)
                    .join(";;")
                    .split(";;")
                    .join(" ")
                    .split(" ");
                })
                .flat()
            )
          ).length
        }
      </div>
      <div className="story-row--tags">
        {dueDate ? dueDate.toDateString() : story.priority_tag}
        {highlight && <div className="story-row--selected-tag">Selected</div>}
      </div>
    </div>
  ) : (
    <div className="story-row--loading">Loading...</div>
  );
};

export default StoryRow;
