import React from "react";
import { useDisplayData } from "../../../Hooks/useDisplayData";
import { useStories } from "../../../Hooks/useStories";
import { useUserData } from "../../../Hooks/useUserData";
import { Phonics, StudentData } from "../../../Models";
import { generateID } from "../../../Utils";

const TrackedWords: React.FC = () => {
  const { storyData } = useStories();
  const { displayData, hideModal } = useDisplayData();
  const { setUser } = useUserData();

  return (
    <div className="modal__tracked-words">
      <div className="modal__prompt">
        <span
          className="user-details--child"
          style={{ marginRight: "unset", cursor: "pointer" }}
          onClick={() => {
            setUser(displayData.student?._id!);
            hideModal();
          }}
        >
          {`${displayData.student?.user_data.name}${
            (displayData.student?.user_data as StudentData).perm_tracked_words
              .length > 0
              ? "'s"
              : ""
          }`}
        </span>
        {(displayData.student?.user_data as StudentData).perm_tracked_words
          .length > 0
          ? ` Tracked Words`
          : ` has no tracked words. Click some words to start tracking!`}
      </div>
      <div className="modal__tracked-words--tracking-container">
        {(displayData.student?.user_data as StudentData).perm_tracked_words.map(
          (wordProperties) => {
            const timesClicked = (displayData.student?.user_data as StudentData)
              .perm_tracked_words[
              (
                displayData.student?.user_data as StudentData
              ).perm_tracked_words.findIndex(
                (val) => val.word === wordProperties.word
              )
            ].times_clicked;
            return (
              <React.Fragment key={generateID()}>
                {timesClicked && timesClicked > 0 && (
                  <div className="modal__tracked-words--tracking-section">
                    <span className="modal__tracked-words--word">
                      {wordProperties.word}
                    </span>

                    <span className="modal__tracked-words__tracking-section--times-clicked">
                      {timesClicked} times clicked
                    </span>

                    <div className="modal__tracked-words__tracking-section--phonic-word-container">
                      {wordProperties.phonics.map((phonic, phonicIndex) => {
                        let currentPhonics: Phonics[] = [];
                        for (const phoneme of phonic.phonemes) {
                          const foundPhonic = storyData.phonics.find(
                            (fullPhonic) => fullPhonic.phoneme === phoneme
                          );
                          if (foundPhonic) {
                            currentPhonics.push(foundPhonic);
                          }
                        }

                        return (
                          <React.Fragment key={generateID()}>
                            <div className="modal__tracked-words__tracking-section--phonic-part-container">
                              <span
                                className={`${
                                  (
                                    displayData.student
                                      ?.user_data as StudentData
                                  ).perm_tracked_words[
                                    (
                                      displayData.student
                                        ?.user_data as StudentData
                                    ).perm_tracked_words.findIndex(
                                      (val) => val.word === wordProperties.word
                                    )
                                  ].positions.includes(phonic.position)
                                    ? phonic.position === 1
                                      ? "modal__tracked-words__phonic-part--first "
                                      : phonic.position ===
                                        wordProperties.phonics.length
                                      ? "modal__tracked-words__phonic-part--last "
                                      : phonic.position === 2
                                      ? "modal__tracked-words__phonic-part--second "
                                      : phonic.position ===
                                        wordProperties.phonics.length - 1
                                      ? "modal__tracked-words__phonic-part--penultimate "
                                      : "modal__tracked-words__phonic-part--middle "
                                    : ""
                                }modal__tracked-words__tracking-section--phonic-part`}
                              >
                                {`${phonic.part}`}
                              </span>
                              <span
                                className={`${
                                  (
                                    displayData.student
                                      ?.user_data as StudentData
                                  ).perm_tracked_words[
                                    (
                                      displayData.student
                                        ?.user_data as StudentData
                                    ).perm_tracked_words.findIndex(
                                      (val) => val.word === wordProperties.word
                                    )
                                  ].positions.includes(phonic.position)
                                    ? phonic.position === 1
                                      ? "modal__tracked-words__phonic-click--first "
                                      : phonic.position ===
                                        wordProperties.phonics.length
                                      ? "modal__tracked-words__phonic-click--last "
                                      : phonic.position === 2
                                      ? "modal__tracked-words__phonic-click--second "
                                      : phonic.position ===
                                        wordProperties.phonics.length - 1
                                      ? "modal__tracked-words__phonic-click--penultimate "
                                      : "modal__tracked-words__phonic-click--middle "
                                    : ""
                                }modal__tracked-words__tracking-section--phonic-click`}
                              ></span>
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                )}
              </React.Fragment>
            );
          }
        )}
      </div>
    </div>
  );
};

export default TrackedWords;
