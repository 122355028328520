import React, { useRef, useState } from "react";
import { useDisplayData } from "../../../Hooks/useDisplayData";
import { useStories } from "../../../Hooks/useStories";
import { useUserData } from "../../../Hooks/useUserData";
import {
  generateID,
  generateRandomNumberString,
  GetBaseURL,
} from "../../../Utils";
import NewEncryption from "../../../Utils/NewEncryption";

interface AddStudentProps {
  setError: (
    value: React.SetStateAction<{
      isActive: boolean;
      type: string;
      message: string;
    }>
  ) => void;
}

type AddedStudent = { name: string; username: string; password: string };

const AddStudent: React.FC<AddStudentProps> = ({ setError }) => {
  const { displayData } = useDisplayData();
  const { storyData } = useStories();
  const { setUser, userData } = useUserData();
  const studentNameRef = useRef<HTMLInputElement>(null);
  const [addedStudents, setAddedStudents] = useState<AddedStudent[]>([]);

  const handleAddStudent = async () => {
    if (studentNameRef.current && studentNameRef.current.value !== "") {
      if (studentNameRef.current.value.trim().includes(" ")) {
        const username = `${
          studentNameRef.current.value.trim().split(" ")[0]
        }${studentNameRef.current.value
          .trim()
          .split(" ")[1]
          .substring(0, 1)}${generateRandomNumberString(4)}`.toLowerCase();
        const password = `${storyData.words[
          Math.floor(Math.random() * storyData.words.length)
        ].text.toLowerCase()}${generateRandomNumberString(4)}`;
        const registerRequest = await fetch(
          `${GetBaseURL()}/api/users/register?key=${
            process.env.REACT_APP_PERSONAL_AUTH_KEY
          }`,
          {
            method: "POST",
            body: JSON.stringify({
              name: studentNameRef.current.value,
              username,
              password: NewEncryption.encrypt(password),
              account_type: "student",
              grade: 0,
            }),
            headers: { "Content-Type": "application/json" },
          }
        );
        const registerResponse = await registerRequest.json();
        if (registerRequest.status === 200) {
          const addStudentRequest = await fetch(
            `${GetBaseURL()}/api/classrooms/addStudent?key=${
              process.env.REACT_APP_PERSONAL_AUTH_KEY
            }`,
            {
              method: "POST",
              body: JSON.stringify({
                classroom_id: displayData.activeClassroom?._id,
                student_id: registerResponse._id,
              }),
              headers: { "Content-Type": "application/json" },
            }
          );
          const addStudentResponse = await addStudentRequest.json();
          if (addStudentRequest.status === 200) {
            setAddedStudents((prev: any) => [
              ...prev,
              {
                name: studentNameRef.current?.value.trim(),
                username,
                password,
              },
            ]);
            setUser(userData._id);
          } else {
            setError({
              type: "error",
              isActive: true,
              message: addStudentResponse.message,
            });
          }
        } else {
          setError({
            type: "error",
            isActive: true,
            message: registerResponse.message,
          });
        }
      } else {
        setError({
          type: "error",
          isActive: true,
          message: "Please enter a first and last name.",
        });
      }
    } else {
      setError({
        type: "error",
        isActive: true,
        message: "Please enter a name.",
      });
    }
  };
  return (
    <div className="modal__add-student">
      <div className="modal__prompt">
        Add a student to {displayData.activeClassroom!.name}
      </div>
      <div className="modal__add-student--query-container">
        <div className="modal__add-student--section">
          <div className="modal__add-student--heading">
            They can connect to your class from their student account.
          </div>
          <div className="modal__add-student--text-container">
            <div className="modal__add-student--text">
              Send them your class code below:
            </div>
            <div className="modal__add-student--value">
              {displayData.activeClassroom!._id}
            </div>
            <div className="modal__add-student--copy-text">
              Copy <i className="fa fa-copy" />
            </div>
          </div>
        </div>
        <div>or</div>
        <div className="modal__add-student--section">
          <div className="modal__add-student--heading">
            You can create a new student account for them.
          </div>
          <div className="modal__add-student--text-container">
            <div className="modal__add-student--text">
              Student First and Last Name
            </div>
            <div className="modal__add-student--input-holder">
              <input
                ref={studentNameRef}
                className="modal__add-student--input"
                type="text"
                placeholder="First and Last Name"
              />
            </div>
            <div
              className="modal__add-student--submit"
              onClick={() => handleAddStudent()}
            >
              Add Student
            </div>
          </div>
        </div>
      </div>
      <div className="modal__add-student--added-students">
        {addedStudents.map((student) => (
          <React.Fragment key={generateID()}>
            <div className="modal__add-student--student-row">
              <div>{student.name}</div>
              <div>{student.username}</div>
              <div>{student.password}</div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
export default AddStudent;
