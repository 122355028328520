import React from "react";
import { useDisplayData } from "../../Hooks/useDisplayData";
import { Classrooms } from "../../Models";

type ClassroomDetailsType = {
  activeClassroom: Classrooms;
};

const ClassroomDetails: React.FC<ClassroomDetailsType> = ({
  activeClassroom,
}) => {
  const { showAddStudentModal, showQuickAssignModal } = useDisplayData();
  return (
    <div className="teacher-portal__classroom-details-container">
      <div className="teacher-portal__classroom-details-item">
        <div>
          <span className="teacher-portal__students-length--value">
            {activeClassroom.active_assignments[0].student
              ? activeClassroom.active_assignments.length
              : 0}
          </span>
          <span className="teacher-portal__students-length--identifier">
            {" "}
            Students
          </span>
        </div>
        <div
          className="teacher-portal__add-student"
          onClick={() => showAddStudentModal(activeClassroom)}
        >
          Add +
        </div>
      </div>

      <div className="teacher-portal__classroom-details-item">
        <div className="teacher-portal__class-code--identifier">Class Code</div>
        <div className="teacher-portal__class-code--value">
          {activeClassroom?._id}
        </div>
      </div>
      {activeClassroom.active_assignments[0].student && (
        <div className="teacher-portal__classroom-details-item">
          <div
            className="teacher-portal__quick-assign"
            style={{ cursor: "pointer" }}
            onClick={() => showQuickAssignModal(activeClassroom, undefined)}
          >
            Quick Assign +
          </div>
        </div>
      )}
    </div>
  );
};

export default ClassroomDetails;
