import React, { useState } from "react";
import Questions from "./Questions";
import Settings from "./Settings";
import Submissions from "./Submissions";

const NewAssignment: React.FC = () => {
  const [activeTab, setActiveTab] = useState("Settings");
  return (
    <div className="assignment-hub__new-assignment">
      <div className="assignment-hub__new-assignment--tab-container">
        <div
          className={`assignment-hub__new-assignment--tab${
            activeTab === "Settings"
              ? " assignment-hub__new-assignment--tab--selected"
              : ""
          }`}
          onClick={() => setActiveTab("Settings")}
        >
          Settings
        </div>
        <div
          className={`assignment-hub__new-assignment--tab${
            activeTab === "Questions"
              ? " assignment-hub__new-assignment--tab--selected"
              : ""
          }`}
          onClick={() => setActiveTab("Questions")}
        >
          Questions
        </div>
        <div
          className={`assignment-hub__new-assignment--tab${
            activeTab === "Submissions"
              ? " assignment-hub__new-assignment--tab--selected"
              : ""
          }`}
          onClick={() => setActiveTab("Submissions")}
        >
          Submissions
        </div>
      </div>
      {activeTab === "Settings" && <Settings />}
      {activeTab === "Questions" && <Questions />}
      {activeTab === "Submissions" && <Submissions />}
    </div>
  );
};

export default NewAssignment;
