import React, { FormEvent } from "react";
import { useDisplayData } from "../../../Hooks/useDisplayData";
import { useUserData } from "../../../Hooks/useUserData";
import { GetBaseURL, ValidateEmail } from "../../../Utils";
import NewEncryption from "../../../Utils/NewEncryption";

interface RegistrationFormProps {
  usernameRef: React.RefObject<HTMLInputElement>;
  passwordRef: React.RefObject<HTMLInputElement>;
  setError: (
    value: React.SetStateAction<{
      isActive: boolean;
      type: string;
      message: string;
    }>
  ) => void;
  userRole: "parent" | "teacher" | null;
  setEmailLogin: React.Dispatch<React.SetStateAction<boolean>>;
}

const RegistrationForm: React.FC<RegistrationFormProps> = ({
  usernameRef,
  passwordRef,
  setError,
  userRole,
  setEmailLogin,
}) => {
  const { hideModal, showLoginModal } = useDisplayData();
  const { setUser } = useUserData();
  const handleRegister = async (e: FormEvent) => {
    e.preventDefault();
    if (
      usernameRef.current &&
      passwordRef.current &&
      usernameRef.current.value !== "" &&
      passwordRef.current.value !== ""
    ) {
      if (passwordRef.current.value.length < 6) {
        setError({
          type: "error",
          isActive: true,
          message: "Please enter at least 6 characters for your password.",
        });
      } else {
        if (!ValidateEmail(usernameRef.current.value)) {
          setError({
            type: "error",
            isActive: true,
            message: "Please enter a valid email.",
          });
        } else {
          const request = await fetch(
            `${GetBaseURL()}/api/users/register?key=${
              process.env.REACT_APP_PERSONAL_AUTH_KEY
            }`,
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                account_type: userRole,
                username: usernameRef.current.value,
                password: NewEncryption.encrypt(passwordRef.current.value),
                device:
                  window.innerWidth <= 600
                    ? "mobile"
                    : window.innerWidth <= 1024
                    ? "tablet"
                    : "desktop",
              }),
            }
          );
          const response = await request.json();
          if (request.status === 200) {
            // SendEmail(
            //   `Please click the following link to complete account verification:\n${process.env.REACT_APP_SITE_URL}/verify/${response._id}`,
            //   usernameRef.current.value,
            //   "No Fuss Readers Email Verification"
            // );
            setUser(response._id);
            hideModal();
            // setError({
            //   isActive: true,
            //   type: "success",
            //   message:
            //     "Email sent! Please verify your account through the provided email.",
            // });
          } else {
            setError({
              isActive: true,
              type: "error",
              message: response.message,
            });
          }
        }
      }
    } else {
      setError({
        isActive: true,
        type: "error",
        message: "Please enter a username AND a password",
      });
    }
  };

  return (
    <form onSubmit={handleRegister} className="modal__form">
      <span
        className="modal__go-back"
        style={{ cursor: "pointer", color: "#e3522a" }}
        onClick={() => setEmailLogin(false)}
      >
        {"Go back"}
      </span>
      <input
        className="modal__input"
        type="text"
        placeholder="Username"
        ref={usernameRef}
      />
      <input
        className="modal__input"
        type="password"
        placeholder="Password"
        ref={passwordRef}
      />
      <input
        className="modal__submit"
        type="submit"
        value="Start Tracking Your Words"
      ></input>
      <div className="modal__instead">
        Already have an account?{" "}
        <span
          className="modal__instead--link"
          onClick={() => {
            showLoginModal();
          }}
        >
          Log in
        </span>{" "}
        instead!
      </div>
    </form>
  );
};

export default RegistrationForm;
