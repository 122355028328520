import { useContext } from "react";
import { Display, DisplayContext } from "../Context/DisplayContext";
import { UserContext } from "../Context/UserContext";
import { ParentData, StudentData, TrackedWords, Users } from "../Models";
import { GetBaseURL } from "../Utils";

export const useUserData = () => {
  const { userData, setUserData } = useContext(UserContext);
  const { displayData, setDisplayData } = useContext(DisplayContext);

  const setUser = (id: string) => {
    localStorage.setItem("id", id);
    setUserData((prev: Users) => ({
      ...prev,
      shouldUpdate: true,
      updateID: id,
    }));
  };

  const logout = () => {
    localStorage.removeItem("id");
    setUserData({
      _id: "",
      account_type: "student",
      user_data: {
        name: "",
        grade: "",
        email: "",
        assessment_scores: [],
        linked_accounts: [],
        tracked_words: [],
        assigned_stories: [],
      },
      subscription_data: {
        plan_type: "free",
        freemium_tallies: {
          words_tracked: 0,
          stories_read: 0,
        },
      },
      shouldUpdate: false,
      displayTutorial: true,
    });
  };

  return {
    userData,
    setUserData,
    setUser,

    logout,
  };
};
