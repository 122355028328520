import React from "react";
import { useUserData } from "../Hooks/useUserData";

const Header: React.FC = () => {
  const { userData, logout } = useUserData();
  return (
    <div className="header">
      <div className="header__logo">
        <div className="header__logo--top">NO FUSS</div>
        <div className="header__logo--bottom">CLASSROOMS</div>
      </div>
      <div className="header__user">
        <div className="header__user--picture">
          <i className="fa fa-user" />
        </div>
        <div className="header__user--info">
          <div className="header__user--name">{userData.user_data.name}</div>
          <div className="header__user--school-district">
            School District: ISG
          </div>
        </div>
        <div className="header__dropdown-arrow">
          <i className="fa fa-chevron-down" />
        </div>
      </div>
    </div>
  );
};
export default Header;
