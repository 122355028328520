import React from "react";
import { Tag } from "../../Models";

type TagFilterProps = {
  selectedTags: Tag[];
  setSelectedTags: (value: React.SetStateAction<Tag[]>) => void;
  givenTag: Tag;
};

const TagFilter: React.FC<TagFilterProps> = ({
  selectedTags,
  setSelectedTags,
  givenTag,
}) => {
  return (
    <div
      className={`teacher-portal__tags-filter--tag teacher-portal__tags-filter--tag--${givenTag
        .toLowerCase()
        .split(" ")
        .join("-")}${
        selectedTags.includes(givenTag)
          ? ` teacher-portal__tags-filter--tag--${givenTag
              .toLowerCase()
              .split(" ")
              .join("-")}--selected`
          : ""
      }`}
      onClick={() =>
        setSelectedTags((prev: Tag[]) =>
          selectedTags.includes(givenTag)
            ? prev.filter((tag) => tag !== givenTag)
            : [...prev, givenTag]
        )
      }
    >
      {givenTag}
    </div>
  );
};

export default TagFilter;
