import React from "react";

const ReportCards: React.FC = () => {
  return (
    <div className="teacher-portal__report-cards">
      <div>Coming Soon!</div>
    </div>
  );
};

export default ReportCards;
