import React, { useEffect, useState } from "react";
import { useDisplayData } from "../../Hooks/useDisplayData";
import { Classrooms, Sorter, sortingTypes, Tag, tagTypes } from "../../Models";
import { generateID } from "../../Utils";
import { FiltersType } from "../Dashboard";
import TagFilter from "./TagFilter";

type ClassroomFiltersType = {
  activeClassroom: Classrooms;
  filters: FiltersType[];
  setFilters: (value: React.SetStateAction<FiltersType[]>) => void;
};

const ClassroomFilters: React.FC<ClassroomFiltersType> = ({
  activeClassroom,
  filters,
  setFilters,
}) => {
  const { setActiveClassroomInContext } = useDisplayData();
  const [ascending, setAscending] = useState(true);
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const [sorter, setSorter] = useState<Sorter>("Alphabetical");

  const sortStudents = (ascendingValue: boolean) => {
    switch (sorter) {
      case "Last Active":
        setActiveClassroomInContext({
          ...activeClassroom,
          active_assignments: activeClassroom.active_assignments.sort(
            (first, second) => {
              if (!first.student.last_active) {
                return ascendingValue ? 1 : -1;
              } else if (!second.student.last_active) {
                return ascendingValue ? -1 : 1;
              } else if (
                first.student.last_active?.valueOf() -
                  second.student.last_active?.valueOf() >
                0
              ) {
                return ascendingValue ? -1 : 1;
              } else if (
                first.student.last_active?.valueOf() -
                  second.student.last_active?.valueOf() <
                0
              ) {
                return ascendingValue ? 1 : -1;
              } else {
                return 0;
              }
            }
          ),
        });
        break;
      case "Alphabetical":
        setActiveClassroomInContext({
          ...activeClassroom,
          active_assignments: activeClassroom.active_assignments.sort(
            (first, second) =>
              first.student.user_data.name > second.student.user_data.name
                ? ascendingValue
                  ? 1
                  : -1
                : ascendingValue
                ? -1
                : 1
          ),
        });
        break;
    }
  };

  // useEffect(() => {
  //   if (sorter !== "") {
  //     sortStudents(ascending);
  //   }
  // }, [sorter]);

  return (
    <div className="teacher-portal__">
      <div className="teacher-portal__classroom-filters">
        <div className="teacher-portal__nametags-filter-container">
          <div className="teacher-portal__tags-filter-container">
            <div className="teacher-portal__tags-filter--title">
              Filter By Tag:
            </div>

            <div className="teacher-portal__tags-filter--tags-container">
              <div className="teacher-portal__tags-filter--row">
                {tagTypes.slice(0, 4).map((tag) => (
                  <React.Fragment key={generateID()}>
                    <TagFilter
                      selectedTags={selectedTags}
                      setSelectedTags={setSelectedTags}
                      givenTag={tag}
                    />
                  </React.Fragment>
                ))}
              </div>
              <div className="teacher-portal__tags-filter--row">
                {tagTypes.slice(4).map((tag) => (
                  <React.Fragment key={generateID()}>
                    <TagFilter
                      selectedTags={selectedTags}
                      setSelectedTags={setSelectedTags}
                      givenTag={tag}
                    />
                  </React.Fragment>
                ))}
                <div className="teacher-portal__tags-filter--tag">Add +</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="teacher-portal__sort-container">
        <div>
          {/* <div className="teacher-portal__results-indicator">
            Results: {activeClassroom.active_assignments.length} Students
          </div> */}
          <div className="teacher-portal__search-container">
            <i
              style={{
                color: "#333",
                fontSize: "1.5rem",
              }}
              className="fa fa-search"
            ></i>
            <input
              className="teacher-portal__search-container--input"
              type="text"
              placeholder="Search by Name"
            />
          </div>
        </div>
        <div className="teacher-portal__sorter">
          <div className="teacher-portal__sort-by">Sort By:</div>
          <div className="teacher-portal__sorter-container">
            {sortingTypes.map((sortingType) => (
              <React.Fragment key={generateID()}>
                <div
                  className="teacher-portal__sorting-type-container"
                  onClick={() => setSorter(sortingType)}
                >
                  <div
                    className={
                      sorter === sortingType
                        ? "teacher-portal__sorting-type--selected"
                        : "teacher-portal__sorting-type"
                    }
                  >
                    {sortingType === "Alphabetical" ? (
                      sorter === "Alphabetical" ? (
                        ascending ? (
                          <>
                            A{" "}
                            <i
                              className="fa fa-arrow-right"
                              style={{ textDecoration: "underline" }}
                            />{" "}
                            Z
                          </>
                        ) : (
                          <>
                            Z{" "}
                            <i
                              className="fa fa-arrow-right"
                              style={{ textDecoration: "underline" }}
                            />{" "}
                            A
                          </>
                        )
                      ) : (
                        <>
                          A <i className="fa fa-arrow-right" /> Z
                        </>
                      )
                    ) : (
                      sortingType
                    )}
                  </div>
                  {sorter === sortingType ? (
                    <div className="teacher-portal__order-container">
                      <div
                        className="teacher-portal__ascending"
                        onClick={() => {
                          setAscending(!ascending);
                          sortStudents(!ascending);
                        }}
                      >
                        <i
                          className={`fa fa-chevron-${
                            ascending ? "up" : "down"
                          }`}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="teacher-portal__order-container"></div>
                  )}
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassroomFilters;
