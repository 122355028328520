import React, { useEffect, useState } from "react";
import { Assignment, Classrooms } from "../../../Models";

interface StudentListProps {
  selectedClasses: Classrooms[];
}

const StudentList: React.FC<StudentListProps> = ({ selectedClasses }) => {
  const [studentsToMap, setStudentsToMap] = useState<Assignment[]>([]);
  console.log(selectedClasses);
  return <div>{selectedClasses.map((classroom) => classroom.name)}</div>;
};

export default StudentList;
