import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDisplayData } from "../../../Hooks/useDisplayData";
import { useStories } from "../../../Hooks/useStories";
import { generateID } from "../../../Utils";
import CompletedAssignmentDetails from "../CompletedAssignmentDetails";

const Completed: React.FC = () => {
  const { displayData } = useDisplayData();
  const { storyData } = useStories();
  const [expandedAssignments, setExpandedAssignments] = useState<string[]>([]);

  return (
    <div className="modal__completed">
      <div className="modal__overdue--heading">
        <div className="modal__overdue--subheading-1 teacher-portal__student--stat-box--completed-text">
          {displayData.assignment?.student.user_data.name}'s Completed
          Assignments
        </div>
        <div className="modal__overdue--subheading-2 teacher-portal__student--stat-box--completed-value">
          {displayData.numberOfAssignments}
        </div>
      </div>
      <div className="modal__completed--assignments">
        {displayData
          .assignment!.assignments.filter(
            (assignment) => assignment.relevant_data
          )
          .map((assignment) => (
            <React.Fragment key={generateID()}>
              <div className="modal__completed--assignment-row">
                <div className="modal__completed--first-row">
                  <div className="modal__completed--story-date">
                    Completed Date: March 3
                  </div>
                  <div className="modal__completed--story-title">
                    {
                      storyData.stories.find(
                        (story) => story._id === assignment.story
                      )!.title
                    }
                  </div>
                  {!window.location.href.includes("markup") && (
                    <Link
                      to={`markup/class${displayData.activeClassroom?._id}-student${displayData.assignment?.student._id}-story${assignment.story}`}
                      target="_blank"
                      className="modal__completed--open-link"
                    >
                      <div className="modal__completed--open-tab-container">
                        <i className="fa fa-external-link modal__completed--new-tab"></i>
                        <div className="modal__completed--open-tab">Open</div>
                      </div>
                    </Link>
                  )}
                </div>
                {expandedAssignments.includes(assignment.story) ? (
                  <CompletedAssignmentDetails
                    view={true}
                    assignment={assignment}
                    setExpandedAssignments={setExpandedAssignments}
                  />
                ) : (
                  <CompletedAssignmentDetails
                    assignment={assignment}
                    setExpandedAssignments={setExpandedAssignments}
                  />
                )}
              </div>
            </React.Fragment>
          ))}
      </div>
    </div>
  );
};

export default Completed;
