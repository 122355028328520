import React from "react";

type StoryRowTopProps = {
  sorter: string;
  setSorter: (value: React.SetStateAction<string>) => void;
  ascending: boolean;
  setAscending: (value: React.SetStateAction<boolean>) => void;
  addDueDate?: boolean;
};

const StoryRowTop: React.FC<StoryRowTopProps> = ({
  sorter,
  setSorter,
  ascending,
  setAscending,
  addDueDate,
}) => {
  return (
    <div className="modal__story-row--top">
      <div className="modal__story-row--first">Cover</div>
      <div
        className="modal__story-row--second"
        onClick={() => {
          if (sorter === "title") {
            setAscending(!ascending);
          }
          setSorter("title");
        }}
      >
        Title{" "}
        {sorter === "title" ? (
          ascending ? (
            <i className="fa fa-arrow-up" />
          ) : (
            <i className="fa fa-arrow-down" />
          )
        ) : null}
      </div>
      <div
        className="modal__story-row--middle"
        onClick={() => {
          if (sorter === "level") {
            setAscending(!ascending);
          }
          setSorter("level");
        }}
      >
        Level{" "}
        {sorter === "level" ? (
          ascending ? (
            <i className="fa fa-arrow-up" />
          ) : (
            <i className="fa fa-arrow-down" />
          )
        ) : null}
      </div>
      <div className="modal__story-row--last">Word Count</div>
      {addDueDate ? (
        <div
          className="modal__story-row--due-date"
          onClick={() => {
            if (sorter === "due date") {
              setAscending(!ascending);
            }
            setSorter("due date");
          }}
        >
          Due Date{" "}
          {sorter === "due date" ? (
            ascending ? (
              <i className="fa fa-arrow-up" />
            ) : (
              <i className="fa fa-arrow-down" />
            )
          ) : null}
        </div>
      ) : (
        <div className="modal__story-row--penultimate">
          {addDueDate ? "Due Date" : "Tags"}
        </div>
      )}
    </div>
  );
};

export default StoryRowTop;
