import React from "react";
import { Note, NoteTagColors } from "../../Models";
import { generateID } from "../../Utils";

interface ColorPickerProps {
  tagIndex: number;
  selectColor: (tagIndex: number, newColorAbbreviation: string) => void;
  currentText: string;
  modifiedNotes?: Note[];
  activeIndex: number;
  setModifiedNotes: React.Dispatch<React.SetStateAction<Note[] | undefined>>;
  updateNotes: () => Promise<void>;
  setColorPicker: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      index: number;
    }>
  >;
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  tagIndex,
  selectColor,

  currentText,
  modifiedNotes,
  activeIndex,
  setModifiedNotes,
  updateNotes,
  setColorPicker,
}) => {
  return (
    <div className="color-picker">
      <div
        contentEditable
        className="color-picker--tag"
        onKeyDown={(e) => {
          if (e.code === "Enter") {
            e.currentTarget.blur();
          }
        }}
        onBlur={(e) => {
          if (
            e.target.textContent !==
            modifiedNotes![activeIndex].tags[tagIndex].text
          ) {
            setTimeout(() => {
              let notesToModify = modifiedNotes;
              notesToModify![activeIndex].tags[tagIndex].text = e.target
                .textContent
                ? e.target.textContent
                : "New Tag";
              setModifiedNotes(notesToModify);
              updateNotes();
            }, 200);
          }
        }}
      >
        {currentText}
      </div>
      <div className="color-picker--colors">
        {NoteTagColors.map((color) => (
          <React.Fragment key={generateID()}>
            <div
              className={`color-picker--div ${color.abbreviation}`}
              onClick={() => {
                if (
                  color.abbreviation !==
                  modifiedNotes![activeIndex].tags[tagIndex].color
                ) {
                  selectColor(tagIndex, color.abbreviation);
                }
                setColorPicker({ show: false, index: -1 });
              }}
            ></div>
          </React.Fragment>
        ))}
      </div>
      <div
        className="color-picker--delete"
        onClick={() => {
          setColorPicker({ show: false, index: -1 });
          let notesToModify = modifiedNotes;
          notesToModify![activeIndex].tags = notesToModify![
            activeIndex
          ].tags.filter((tag, index) => tagIndex !== index);
          setModifiedNotes(notesToModify);
          updateNotes();
        }}
      >
        Delete Tag
      </div>
    </div>
  );
};

export default ColorPicker;
