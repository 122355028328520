import React from "react";
import { Link } from "react-router-dom";
import Modal from "../Components/Partials/Modal";
import TeacherPortal from "./TeacherPortal";
import { useDisplayData } from "../Hooks/useDisplayData";
import { useUserData } from "../Hooks/useUserData";

const Home: React.FC = () => {
  const { userData } = useUserData();
  const { displayData, showLoginModal } = useDisplayData();

  return !userData._id && userData._id !== "" ? (
    <section>
      <div>Loading...</div>
    </section>
  ) : userData._id === "" ? (
    <section>
      <div onClick={() => showLoginModal()}>Login</div>
      {displayData.showModal && <Modal />}
    </section>
  ) : (
    <TeacherPortal />
  );
};

export default Home;
