import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDisplayData } from "../Hooks/useDisplayData";
import { useUserData } from "../Hooks/useUserData";
import {
  Assignment,
  Classrooms,
  Filters,
  StudentData,
  TeacherData,
} from "../Models";
import {
  ConvertGradeToNumberOrString,
  decrypt,
  generateID,
  GetBaseURL,
} from "../Utils";

import Modal from "./Partials/Modal";
import ClassroomDetails from "./Partials/ClassroomDetails";
import ClassroomFilters from "./Partials/ClassroomFilters";
import StudentRow from "./Partials/StudentRow";

export type FiltersType = {
  _id: string;
  filter: Filters;
};

const Dashboard: React.FC = () => {
  const { userData } = useUserData();
  const { displayData, showAddClassroomModal, setActiveClassroomInContext } =
    useDisplayData();
  const [classroomData, setClassroomData] = useState<Classrooms[]>([]);
  const [activeClassroom, setActiveClassroom] = useState<
    Classrooms | undefined
  >(displayData.activeClassroom ? displayData.activeClassroom : undefined);
  const [filters, setFilters] = useState<FiltersType[]>([]);
  // const [globalFilter, setGlobalFilter] = useState("Reading");

  useEffect(() => {
    if (
      userData.account_type === "teacher" &&
      (userData.user_data as TeacherData).classrooms.length === 0
    ) {
      showAddClassroomModal();
    }
    if (
      userData.account_type === "teacher" &&
      (userData.user_data as TeacherData).classrooms.length > 0
    ) {
      fetch(
        `${GetBaseURL()}/api/classrooms/get?key=${
          process.env.REACT_APP_PERSONAL_AUTH_KEY
        }`,
        {
          method: "POST",
          body: JSON.stringify({
            classroom_ids: (userData.user_data as TeacherData).classrooms.map(
              (classroom: Classrooms) => classroom._id
            ),
          }),
          headers: { "Content-Type": "application/json" },
        }
      ).then(async (request) => {
        const response = await request.json();
        setClassroomData(response.classrooms);
        if (displayData.activeClassroom) {
          const classroomToSet: Classrooms | undefined =
            response.classrooms.find(
              (classroom: Classrooms) =>
                classroom._id === displayData.activeClassroom?._id
            );
          if (classroomToSet) {
            setActiveClassroom(classroomToSet);
            setFilters(
              classroomToSet.active_assignments.map((assignment) => {
                return { _id: assignment.student._id, filter: "Reading" };
              })
            );
          }
        } else {
          setActiveClassroom(response.classrooms[0]);
          if (response.classrooms[0].active_assignments.length > 1) {
            setFilters(
              response.classrooms[0].active_assignments.map(
                (assignment: Assignment) => {
                  return { _id: assignment.student._id, filter: "Reading" };
                }
              )
            );
          }
        }
      });
    }
  }, [userData]);

  return (
    <div className="teacher-portal__classrooms">
      <div className="teacher-portal__classroom-name-container">
        {classroomData.map((classroom) => (
          <React.Fragment key={generateID()}>
            <div
              className={
                activeClassroom?._id === classroom._id
                  ? "teacher-portal__classroom-name teacher-portal__classroom-name--active"
                  : "teacher-portal__classroom-name"
              }
              onClick={() => {
                setActiveClassroom(classroom);
                setActiveClassroomInContext(classroom);
                if (classroom.active_assignments.length > 1) {
                  setFilters(
                    classroom.active_assignments.map((assignment) => {
                      return { _id: assignment.student._id, filter: "Reading" };
                    })
                  );
                }
              }}
            >
              {classroom.name}
            </div>
          </React.Fragment>
        ))}
        <div
          className="teacher-portal__classroom-name"
          onClick={() => showAddClassroomModal()}
        >
          Add Class
        </div>
      </div>
      {activeClassroom && (
        <>
          <ClassroomDetails activeClassroom={activeClassroom} />
          <ClassroomFilters
            activeClassroom={activeClassroom}
            filters={filters}
            setFilters={setFilters}
          />
        </>
      )}

      <div className="teacher-portal__classroom-grid">
        {activeClassroom !== undefined &&
          activeClassroom.active_assignments[0].student &&
          activeClassroom.active_assignments
            .map((assignment) => assignment.student)
            .map((student) => (
              <React.Fragment key={generateID()}>
                <StudentRow
                  activeClassroom={activeClassroom}
                  student={student}
                  filters={filters}
                  setFilters={setFilters}
                />
              </React.Fragment>
            ))}
      </div>
    </div>
  );
};

export default Dashboard;
