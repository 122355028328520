import { Users } from ".";

export type Classrooms = {
  _id?: string;
  name: string;
  teacher: Users;
  active_assignments: Assignment[];
};

export type Assignment = {
  student: Users;
  assignments: StudentAssignment[];
  notes: Note[];
};

export type Note = {
  date_updated: Date;
  tags: NoteTag[];
  internal: boolean;
  title: string;
  content: string;
};

export type NoteTag = {
  text: string;
  color: string;
};

export type StudentAssignment = {
  story: string;
  due_date: Date;
  relevant_data?: {
    audio_src: string;
    accuracy: number;
    date_recorded: Date;
    missed_words: string[];
    quiz_score: string;
    transcript: string;
  };
  teacher_data?: {
    markup?: MarkedUpWord[];
    manual_score?: number;
    notes?: string;
  };
};

export type MarkedUpWord = {
  page_index: number;
  line_index: number;
  word_index: number;
  word: string;
  mistake: MistakesType;
};

export type Filters = "All" | "Reading" | "Math" | "English";

export type Tag =
  | "Overdue"
  | "Level Up"
  | "At Risk"
  | "Reading Recovery"
  | "IEP"
  | "Active Parent"
  | "Exceeds Expectations";
export const tagTypes: Tag[] = [
  "Overdue",
  "Level Up",
  "At Risk",
  "Reading Recovery",
  "IEP",
  "Active Parent",
  "Exceeds Expectations",
];

export type Sorter = "Last Active" | "Alphabetical";
export const sortingTypes: Sorter[] = ["Last Active", "Alphabetical"];

export type MistakesType =
  | "Mispronunciation"
  | "Repetition"
  | "Omission"
  | "Self-Correction"
  | "Other";

export type Mistakes = {
  mispronunciations: number;
  omissions: number;
  repetitions: number;
  self_corrections: number;
  other: number;
};

export type NoteTagColorType = {
  name: string;
  abbreviation: string;
};

export const NoteTagColors: NoteTagColorType[] = [
  {
    name: "Light Greenish Blue",
    abbreviation: "lgb",
  },
  {
    name: "Faded Poster",
    abbreviation: "fp",
  },
  {
    name: "Green Darner Tail",
    abbreviation: "gdt",
  },
  {
    name: "Shy Moment",
    abbreviation: "sm",
  },
  {
    name: "City Lights",
    abbreviation: "cl",
  },
  {
    name: "Mint Leaf",
    abbreviation: "ml",
  },
  {
    name: "Robin's Egg Blue",
    abbreviation: "reb",
  },
  {
    name: "Electron Blue",
    abbreviation: "eb",
  },
  {
    name: "Exodus Fruit",
    abbreviation: "ef",
  },
  {
    name: "Soothing Breeze",
    abbreviation: "sb",
  },
  {
    name: "Sour Lemon",
    abbreviation: "sl",
  },
  {
    name: "First Date",
    abbreviation: "fd",
  },
  {
    name: "Pink Glamour",
    abbreviation: "pg",
  },
  {
    name: "Pico-8 Pink",
    abbreviation: "p8p",
  },
  {
    name: "American River",
    abbreviation: "ar",
  },
  {
    name: "Bright Yarrow",
    abbreviation: "by",
  },
  {
    name: "Orangeville",
    abbreviation: "ov",
  },
  {
    name: "Chi-Gong",
    abbreviation: "cg",
  },
  {
    name: "Prunus Avium",
    abbreviation: "pa",
  },
  {
    name: "Dracula Orchid",
    abbreviation: "do",
  },
];
