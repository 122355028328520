import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDisplayData } from "../../../Hooks/useDisplayData";
import { useUserData } from "../../../Hooks/useUserData";
import { Classrooms, TeacherData } from "../../../Models";
import { generateID, GetBaseURL } from "../../../Utils";
import StudentList from "./StudentList";

const Settings: React.FC = () => {
  const { userData } = useUserData();
  const { setSettingAssignment } = useDisplayData();
  const [subject, setSubject] = useState("");
  const [classroomData, setClassroomData] = useState<Classrooms[]>([]);
  const [selectedClasses, setSelectedClasses] = useState<string[]>([]);
  const [location, setLocation] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [homework, setHomework] = useState(true);
  const [showStudentList, setShowStudentList] = useState(false);
  // const [frequency, setFrequency] = useState("");

  useEffect(() => {
    if (
      userData.account_type === "teacher" &&
      (userData.user_data as TeacherData).classrooms.length > 0
    ) {
      fetch(
        `${GetBaseURL()}/api/classrooms/get?key=${
          process.env.REACT_APP_PERSONAL_AUTH_KEY
        }`,
        {
          method: "POST",
          body: JSON.stringify({
            classroom_ids: (userData.user_data as TeacherData).classrooms.map(
              (classroom: Classrooms) => classroom._id
            ),
          }),
          headers: { "Content-Type": "application/json" },
        }
      ).then(async (request) => {
        const response = await request.json();
        setClassroomData(response.classrooms);
      });
    }
  }, [userData]);

  useEffect(() => {
    if (
      subject !== "" ||
      selectedClasses.length > 0 ||
      location.length > 0 ||
      selectedDate
    ) {
      setSettingAssignment(true);
      window.addEventListener("beforeunload", (e) => {
        e.returnValue = "You sure?";
      });
    } else {
      setSettingAssignment(false);
    }
  }, [subject, selectedClasses, location, selectedDate]);

  return (
    <div className="assignment-hub__settings">
      <div className="assignment-hub__settings--class-row">
        <div className="assignment-hub__settings--class-text">Class:</div>
        {classroomData.length > 0 ? (
          classroomData.map((classroom, index) => (
            <React.Fragment key={index}>
              <div
                className={`assignment-hub__settings--class${
                  selectedClasses.includes(classroom.name)
                    ? " assignment-hub__settings--class--selected"
                    : ""
                }`}
                onClick={() =>
                  setSelectedClasses((prev: string[]) =>
                    prev.includes(classroom.name)
                      ? prev.filter(
                          (classroomName) => classroomName !== classroom.name
                        )
                      : [...prev, classroom.name]
                  )
                }
              >
                {classroom.name}
              </div>
            </React.Fragment>
          ))
        ) : (
          <div className="assignment-hub__settings--no-classes">No Classes</div>
        )}
      </div>
      <div
        className="assignment-hub__settings--select-students"
        onClick={() => setShowStudentList(true)}
      >
        Select Students
      </div>
      {showStudentList && (
        <StudentList
          selectedClasses={classroomData.filter((classroom) =>
            selectedClasses.includes(classroom.name)
          )}
        />
      )}
      <div className="assignment-hub__settings--subject-container">
        <div
          className={`assignment-hub__settings--subject${
            subject === "Math"
              ? " assignment-hub__settings--subject--selected"
              : ""
          }`}
          onClick={() => {
            setSubject("Math");
            setLocation((prev: string[]) =>
              prev.filter((location) => location !== "No Fuss Readers")
            );
          }}
        >
          Math
        </div>
        <div
          className={`assignment-hub__settings--subject${
            subject === "Reading"
              ? " assignment-hub__settings--subject--selected"
              : ""
          }`}
          onClick={() => setSubject("Reading")}
        >
          Reading
        </div>
        <div
          className={`assignment-hub__settings--subject${
            subject === "English"
              ? " assignment-hub__settings--subject--selected"
              : ""
          }`}
          onClick={() => {
            setSubject("English");
            setLocation((prev: string[]) =>
              prev.filter((location) => location !== "No Fuss Readers")
            );
          }}
        >
          English
        </div>
      </div>
      <div
        className="assignment-hub__settings--homework"
        onClick={() => setHomework(!homework)}
      >
        {homework ? "Homework" : "Quiz"}
      </div>{" "}
      {/* Homework vs Quiz (same, just not graded vs graded) */}
      <div className="assignment-hub__settings--location-container">
        <div className="assignment-hub__settings--location-text">
          Where to take it:
        </div>
        <div
          className={`assignment-hub__settings--location${
            location.includes("Project MoonRock")
              ? " assignment-hub__settings--location--selected"
              : ""
          }`}
          onClick={() =>
            setLocation((prev: string[]) =>
              prev.includes("Project MoonRock")
                ? prev.filter((location) => location !== "Project MoonRock")
                : [...prev, "Project MoonRock"]
            )
          }
        >
          <img
            src="https://study-portal.s3.us-east-2.amazonaws.com/moonrock-logo.png"
            alt="Book Icon"
            className="assignment-hub__settings__moonrok-button-img"
          />
        </div>
        {/* <div
          className={`assignment-hub__settings--location${
            location.includes("No Fuss Classrooms")
              ? " assignment-hub__settings--location--selected"
              : ""
          }`}
          onClick={() =>
            setLocation((prev: string[]) =>
              prev.includes("No Fuss Classrooms")
                ? prev.filter((location) => location !== "No Fuss Classrooms")
                : [...prev, "No Fuss Classrooms"]
            )
          }
        >
          <span>No Fuss Classrooms</span>
        </div> */}
        <div
          style={
            subject !== "Reading"
              ? {
                  pointerEvents: "none",
                  background: "lightgray",
                  opacity: "0.5",
                }
              : undefined
          }
          className={`assignment-hub__settings--location${
            location.includes("No Fuss Readers")
              ? " assignment-hub__settings--location--selected"
              : ""
          }`}
          onClick={() =>
            setLocation((prev: string[]) =>
              prev.includes("No Fuss Readers")
                ? prev.filter((location) => location !== "No Fuss Readers")
                : [...prev, "No Fuss Readers"]
            )
          }
        >
          <span className="assignment-hub__settings--button-text">
            <span>No Fuss Readers</span>
            <span>{subject !== "Reading" && ` (${subject} Unavailable)`}</span>
          </span>
        </div>
        {/* <div
          className={`assignment-hub__settings--location${
            location.includes("Print")
              ? " assignment-hub__settings--location--selected"
              : ""
          }`}
          onClick={() =>
            setLocation((prev: string[]) =>
              prev.includes("Print")
                ? prev.filter((location) => location !== "Print")
                : [...prev, "Print"]
            )
          }
        >
          <span>Print</span>
        </div> */}
      </div>
      {/* <div className="assignment-hub__settings--frequency-container">
        <div
          className={`assignment-hub__settings--frequency${
            frequency === "One-Time"
              ? " assignment-hub__settings--frequency--selected"
              : ""
          }`}
          onClick={() => setFrequency("One-Time")}
        >
          One-Time
        </div>
        <div
          className={`assignment-hub__settings--frequency${
            frequency === "Recurring"
              ? " assignment-hub__settings--frequency--selected"
              : ""
          }`}
          onClick={() => setFrequency("Recurring")}
        >
          Recurring
        </div>
      </div> */}
      <div className="assignment-hub__settings--due-date-container">
        <div className="assignment-hub__settings--due-date--text">Due:</div>
        <div className="assignment-hub__settings--due-date--option">
          {selectedDate?.toDateString()}
          {" at "}
          {selectedDate
            ? selectedDate.getHours() > 12
              ? selectedDate.getHours() - 12
              : selectedDate.getHours()
            : null}
          :
          {selectedDate
            ? selectedDate.getUTCMinutes() > 9
              ? null
              : "0"
            : null}
          {selectedDate?.getUTCMinutes()}
          {selectedDate
            ? selectedDate.getHours() > 12
              ? "PM"
              : "AM"
            : null}{" "}
        </div>
        {console.log(selectedDate?.getHours())}
        <input
          type="datetime-local"
          defaultValue={new Date(
            new Date(Date.now()).setDate(new Date().getDate() + 1)
          )
            .toISOString()
            .split(":")
            .filter((datePart, index) => index < 2)
            .join(":")}
          min={new Date(new Date(Date.now()).setDate(new Date().getDate() + 1))
            .toISOString()
            .split(":")
            .filter((datePart, index) => index < 2)
            .join(":")}
          onChange={(e) => {
            setSelectedDate(new Date(e.target.value));
          }}
        />
      </div>
      <div className="assignment-hub__settings--submit">Assign</div>
    </div>
  );
};

export default Settings;
