import { useContext } from "react";
import { StoryContext, StoryDataType } from "../Context/StoryContext";
import { TopCategories } from "../Models";

export const useStories = () => {
  const { storyData, setStoryData } = useContext(StoryContext);

  return {
    storyData,
  };
};
