import React from "react";
import { StudentData } from "../../Models";
import { encrypt } from "../../Utils";

const ButtonsContainer: React.FC = () => {
  return (
    <div className="buttons-container__buttons-container">
      <div
        className="buttons-container__go-to-stories"
        onClick={() =>
          document
            .getElementById("filter-start")
            ?.scrollIntoView({ behavior: "smooth" })
        }
      >
        <div className="buttons-container__reading-button-img-holder">
          <img
            src="https://study-portal.s3.us-east-2.amazonaws.com/Book-Button-Icon.svg"
            alt="Book Icon"
            className="buttons-container__reading-button-img"
          />
        </div>
        <div className="buttons-container__reading-button-text">Books</div>
      </div>
      <div
        className="buttons-container__go-to-worksheets"
        // onClick={() =>
        //   window.open(
        //     `https://nofusstutors.com/readingRedirect/${
        //       userData.account_type === "parent"
        //         ? encrypt(userData.user_data.email!)
        //         : encrypt(
        //             (userData.user_data as StudentData)
        //               .linked_accounts[0].user_data.email!
        //           )
        //     }`,
        //     "_blank"
        //   )
        // }
      >
        <div className="buttons-container__worksheet-button-img-holder">
          <img
            src="https://study-portal.s3.us-east-2.amazonaws.com/Notebook-Button-Icon.svg"
            alt="Book Icon"
            className="buttons-container__reading-button-img"
          />
        </div>
        <div className="buttons-container__reading-button-text">Worksheets</div>
      </div>
      <div
        className="buttons-container__go-to-MoonRok"
        // onClick={() => showComingSoonModal("MoonRok")}
      >
        <div className="buttons-container__moonrok-button-img-holder">
          <img
            src="https://study-portal.s3.us-east-2.amazonaws.com/moonrock-logo.png"
            alt="Book Icon"
            className="buttons-container__moonrok-button-img"
          />
        </div>
        <div className="buttons-container__reading-button-text">
          Project Moon Rock
        </div>
      </div>
      <div
        className="buttons-container__go-to-games"
        // onClick={() => showGamesModal()}
      >
        <div className="buttons-container__game-button-img-holder">
          <img
            src="https://study-portal.s3.us-east-2.amazonaws.com/Game-Button-Icon.svg"
            alt="Book Icon"
            className="buttons-container__reading-button-img"
          />
        </div>
        <div className="buttons-container__reading-button-text">Mini Games</div>
      </div>
    </div>
  );
};

export default ButtonsContainer;
