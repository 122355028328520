import React, { useState } from "react";
import { useDisplayData } from "../Hooks/useDisplayData";
import Assigned from "./Partials/AssignmentHub/Assigned";
import Drafts from "./Partials/AssignmentHub/Drafts";
import NewAssignment from "./Partials/AssignmentHub/NewAssignment";
import Returned from "./Partials/AssignmentHub/Returned";

const AssignmentHub: React.FC = () => {
  const { displayData } = useDisplayData();
  const [createAssignment, setCreateAssignment] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [activeTab, setActiveTab] = useState("Assigned");
  return (
    <div className="assignment-hub">
      {showDialog && displayData.settingAssignment && (
        <div className="modal">
          <div>You sure?</div>{" "}
          <div onClick={() => setShowDialog(false)}>Cancel</div>{" "}
          <div
            onClick={() => {
              setCreateAssignment(false);
              setShowDialog(false);
            }}
          >
            Confirm
          </div>
        </div>
      )}
      {createAssignment ? (
        <>
          <div
            className="assignment-hub__close-assignment-creation"
            onClick={() =>
              displayData.settingAssignment
                ? setShowDialog(true)
                : setCreateAssignment(false)
            }
          >
            Close
          </div>
          <NewAssignment />
        </>
      ) : (
        <>
          <div
            className="assignment-hub__create-assignment"
            onClick={() => setCreateAssignment(true)}
          >
            Create New +
          </div>
          <div className="assignment-hub__tab-container">
            <div
              className={`assignment-hub__tab${
                activeTab === "Assigned" ? " assignment-hub__tab--selected" : ""
              }`}
              onClick={() => setActiveTab("Assigned")}
            >
              Assigned
            </div>
            <div
              className={`assignment-hub__tab${
                activeTab === "Returned" ? " assignment-hub__tab--selected" : ""
              }`}
              onClick={() => setActiveTab("Returned")}
            >
              Returned
            </div>
            <div
              className={`assignment-hub__tab${
                activeTab === "Drafts" ? " assignment-hub__tab--selected" : ""
              }`}
              onClick={() => setActiveTab("Drafts")}
            >
              Drafts
            </div>
          </div>
          {activeTab === "Assigned" && <Assigned />}
          {activeTab === "Returned" && <Returned />}
          {activeTab === "Drafts" && <Drafts />}
        </>
      )}
    </div>
  );
};

export default AssignmentHub;
