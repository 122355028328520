import React from "react";
import { useDisplayData } from "../../../Hooks/useDisplayData";
import { Assignment, Classrooms, StudentData, Users } from "../../../Models";
import NewEncryption from "../../../Utils/NewEncryption";

type ReadingProps = {
  activeClassroom: Classrooms;
  student: Users;
  studentAssignment?: Assignment;
};

const Reading: React.FC<ReadingProps> = ({
  activeClassroom,
  student,
  studentAssignment,
}) => {
  const {
    showNotesModal,
    showQuickAssignModal,
    showToDoModal,
    showOverdueModal,
    showCompletedModal,
    showChangeReadingLevelModal,
    showTrackedWordsModal,
  } = useDisplayData();
  return (
    <div className="teacher-portal__student--row teacher-portal__student--row--reading">
      <div
        className="teacher-portal__student--stat-container"
        onClick={() => {
          showNotesModal(
            student,
            studentAssignment
              ? studentAssignment.notes
                ? studentAssignment.notes.sort(
                    (first, second) =>
                      new Date(second.date_updated).valueOf() -
                      new Date(first.date_updated).valueOf()
                  )
                : []
              : []
          );
        }}
      >
        <div className="teacher-portal__student--stat-title">Student Notes</div>
        <div className="teacher-portal__student--stat-box teacher-portal__student--stat-box--notes">
          <div className="teacher-portal__student--stat-box--notes-list">
            <div className="teacher-portal__student--stat-box--notes-row">
              List
            </div>
          </div>
          <div className="teacher-portal__student--stat-box--change-level reading-bg">
            Add
          </div>
        </div>
      </div>
      <div className="teacher-portal__student--stat-container">
        <div
          onClick={() => showQuickAssignModal(activeClassroom, student)}
          className="teacher-portal__student--stat-title teacher-portal__student--stat-title--assign reading-bg"
        >
          Assign +
        </div>
        <div className="teacher-portal__student--stat-box">
          <div
            className="teacher-portal__student--stat-box--row teacher-portal__student--stat-box--row-assigned"
            onClick={() =>
              showToDoModal(
                studentAssignment,
                studentAssignment?.assignments.filter(
                  (assignment) =>
                    new Date(assignment.due_date).valueOf() >
                      new Date(Date.now()).valueOf() &&
                    !assignment.relevant_data
                ).length
              )
            }
          >
            <div className="teacher-portal__student--stat-box--to-do-text">
              To Do
            </div>
            <div className="teacher-portal__student--stat-box--to-do-value">
              {
                studentAssignment?.assignments.filter(
                  (assignment) =>
                    new Date(assignment.due_date).valueOf() >
                      new Date(Date.now()).valueOf() &&
                    !assignment.relevant_data
                ).length
              }
            </div>
          </div>
          <div
            className="teacher-portal__student--stat-box--row teacher-portal__student--stat-box--row-assigned"
            onClick={() =>
              showOverdueModal(
                studentAssignment,
                studentAssignment?.assignments.filter(
                  (assignment) =>
                    new Date(assignment.due_date).valueOf() <
                      new Date(Date.now()).valueOf() &&
                    !assignment.relevant_data
                ).length
              )
            }
          >
            <div className="teacher-portal__student--stat-box--overdue-text">
              Overdue
            </div>
            <div className="teacher-portal__student--stat-box--overdue-value">
              {
                studentAssignment?.assignments.filter(
                  (assignment) =>
                    new Date(assignment.due_date).valueOf() <
                      new Date(Date.now()).valueOf() &&
                    !assignment.relevant_data
                ).length
              }
            </div>
          </div>
          <div
            className="teacher-portal__student--stat-box--row teacher-portal__student--stat-box--row-assigned"
            onClick={() => {
              showCompletedModal(
                studentAssignment,
                studentAssignment?.assignments.filter(
                  (assignment) => assignment.relevant_data
                ).length
              );
            }}
          >
            <div className="teacher-portal__student--stat-box--completed-text">
              Completed
            </div>
            <div className="teacher-portal__student--stat-box--completed-value">
              {
                studentAssignment?.assignments.filter(
                  (assignment) => assignment.relevant_data
                ).length
              }
            </div>
          </div>
        </div>
      </div>
      <div className="teacher-portal__student--stat-container">
        <div className="teacher-portal__student--stat-title">Reading Level</div>
        <div
          className="teacher-portal__student--stat-box"
          onClick={() => showChangeReadingLevelModal(student)}
        >
          <div className="teacher-portal__student--stat-box--level-container">
            <span className="teacher-portal__student--stat-box--level-text">
              Level
            </span>
            <span
              className={`teacher-portal__student--stat-box--level-value level-${
                (student.user_data as StudentData).reading_level
              }`}
            >
              {(student.user_data as StudentData).reading_level}
            </span>
          </div>
          <div className="teacher-portal__student--stat-box--change-level reading-bg">
            Change
          </div>
        </div>
      </div>
      <div
        className="teacher-portal__student--stat-container"
        onClick={() => showTrackedWordsModal(student)}
      >
        <div className="teacher-portal__student--stat-title">
          Tracked words to revisit
        </div>
        <div className="teacher-portal__student--stat-box">
          {(student.user_data as StudentData).perm_tracked_words.length > 0 ? (
            <div className="teacher-portal__student--stat-box--tracked-words-container">
              <span className="teacher-portal__student--stat-box--tracked-word">
                {(student.user_data as StudentData).perm_tracked_words.length}{" "}
                tracked words
              </span>
            </div>
          ) : (
            <div className="teacher-portal__student--stat-box--tracked-words-container">
              <span className="teacher-portal__student--stat-box--no-tracked-words">
                No tracked words
              </span>
            </div>
          )}

          {(student.user_data as StudentData).perm_tracked_words.length > 0 && (
            <div className="teacher-portal__student--stat-box--see-more">
              See Words
            </div>
          )}
        </div>
      </div>
      <div className="teacher-portal__student--stat-container">
        <div className="teacher-portal__student--stat-title">Comprehension</div>
        <div className="teacher-portal__student--stat-box">
          {(student.user_data as StudentData).stories_read.length > 0 ? (
            <div className="teacher-portal__student--stat-box--comprehension">
              <div className="teacher-portal__student--stat-box--comp-percentage">
                {Math.round(
                  ((student.user_data as StudentData).stories_read
                    .filter((storyRead) => !storyRead.scores.includes("0/0"))
                    .reduce(
                      (prev, curr) =>
                        prev + Number(curr.scores[curr.scores.length - 1][0]),
                      0
                    ) /
                    ((student.user_data as StudentData).stories_read.filter(
                      (storyRead) => !storyRead.scores.includes("0/0")
                    ).length *
                      3)) *
                    10000
                ) / 100}
                %{" "}
              </div>
              <div>
                {(student.user_data as StudentData).stories_read
                  .filter((storyRead) => !storyRead.scores.includes("0/0"))
                  .reduce(
                    (prev, curr) =>
                      prev + Number(curr.scores[curr.scores.length - 1][0]),
                    0
                  )}{" "}
                /{" "}
                {(student.user_data as StudentData).stories_read.filter(
                  (storyRead) => !storyRead.scores.includes("0/0")
                ).length * 3}
              </div>
              <div></div>
            </div>
          ) : (
            <div className="teacher-portal__student--stat-box--comprehension-none">
              No questions answered
            </div>
          )}
        </div>
      </div>
      <div className="teacher-portal__student--stat-container">
        <div className="teacher-portal__student--stat-title">Login Info</div>
        <div className="teacher-portal__student--stat-box">
          <div className="teacher-portal__student--stat-box--user-pass-wrapper">
            <div className="teacher-portal__student--stat-box--username">
              <div className="teacher-portal__student--stat-box--user-pass-title">
                {" "}
                Username:
              </div>{" "}
              <div>{(student.user_data as StudentData).username}</div>
            </div>
            <div className="teacher-portal__student--stat-box--password">
              <div className="teacher-portal__student--stat-box--user-pass-title">
                {" "}
                Password:{" "}
              </div>
              <div>
                {" "}
                {NewEncryption.decrypt(
                  (student.user_data as StudentData).password!
                )}
              </div>
            </div>
          </div>
          <div className="teacher-portal__student--stat-box--see-more">
            <div className="teacher-portal__student--stat-box--copy-text">
              Copy <i className="fa fa-copy" aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reading;
