import React, { useState, useEffect } from "react";

import {
  Assignment,
  Classrooms,
  Note,
  Story,
  StudentData,
  TrackedWords,
  Users,
} from "../Models";
export interface DisplayContextProps {
  displayData: Display;
  setDisplayData: (displayData: any) => void;
}

export type Display = {
  showModal: boolean;
  login?: boolean;
  register?: boolean;
  addClassroom?: boolean;
  addStudent?: boolean;
  activeClassroom?: Classrooms;
  student?: Users;
  quickAssign?: boolean;
  singleAssign?: boolean;
  showCompleted?: boolean;
  assignment?: Assignment;
  showToDo?: boolean;
  showOverdue?: boolean;
  changeReadingLevel?: boolean;
  numberOfAssignments?: number;
  trackedWords?: boolean;
  notes?: boolean;
  settingAssignment?: boolean;
  studentNotes?: Note[];
};

const DisplayContext = React.createContext({} as DisplayContextProps);

const DisplayContextProvider = (props: any) => {
  const [displayData, setDisplayData] = useState<Display>({ showModal: false });

  const providerValue: DisplayContextProps = {
    displayData,
    setDisplayData,
  };

  return (
    <DisplayContext.Provider value={providerValue}>
      {props.children}
    </DisplayContext.Provider>
  );
};

export { DisplayContext, DisplayContextProvider };
