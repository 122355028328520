import React, { useEffect, useState } from "react";

import { Classrooms, StudentData, Users } from "../../Models";
import { ConvertGradeToNumberOrString, GetBaseURL } from "../../Utils";
import { FiltersType } from "../Dashboard";
import moment from "moment";

import Reading from "./StudentRow/Reading";
import All from "./StudentRow/All";
import Math from "./StudentRow/Math";
import English from "./StudentRow/English";

type StudentRowType = {
  activeClassroom: Classrooms;
  student: Users;
  filters: FiltersType[];
  setFilters: (value: React.SetStateAction<FiltersType[]>) => void;
};

const StudentRow: React.FC<StudentRowType> = ({
  activeClassroom,
  student,
  filters,
  setFilters,
}) => {
  const [parentEmail, setParentEmail] = useState<string | undefined>(undefined);
  const studentAssignment = activeClassroom.active_assignments.find(
    (assignment) => assignment.student._id === student._id
  );
  const displayedTrackedWord = (student.user_data as StudentData)
    .perm_tracked_words[
    (student.user_data as StudentData).perm_tracked_words.length - 5
  ];

  const fetchParent = async () => {
    const request = await fetch(
      `${GetBaseURL()}/api/users/fetchSingle?key=${
        process.env.REACT_APP_PERSONAL_AUTH_KEY
      }`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: (student.user_data as StudentData).linked_accounts[0],
        }),
      }
    );
    const response = await request.json();
    if (request.status === 200) {
      setParentEmail(response.user[0].user_data.email);
    } else {
      setParentEmail(undefined);
    }
  };

  useEffect(() => {
    if ((student.user_data as StudentData).linked_accounts[0]) {
      fetchParent();
    }
  }, [student]);

  return (
    <div
      className={`teacher-portal__student-container ${
        filters
          .find((individual) => individual._id === student._id)
          ?.filter.toLowerCase() + "-border"
      }`}
    >
      <div className="teacher-portal__student--row">
        <div className="teacher-portal__student--name-container">
          <div className="teacher-portal__student--name">
            {student.user_data.name}
          </div>
          <div className="teacher-portal__student--grade">
            {ConvertGradeToNumberOrString(
              (student.user_data as StudentData).grade
            )}
          </div>
        </div>
        <div className="teacher-portal__student--subjects-filter">
          <div
            className={`teacher-portal__student--subject--all${
              filters.find((individual) => individual._id === student._id)
                ?.filter === "All"
                ? "--selected"
                : ""
            } teacher-portal__student--subject`}
            onClick={() =>
              setFilters((prev: FiltersType[]) => [
                ...prev.filter((individual) => individual._id !== student._id),
                { _id: student._id, filter: "All" },
              ])
            }
          >
            All
          </div>
          <div
            className={`teacher-portal__student--subject--reading${
              filters.find((individual) => individual._id === student._id)
                ?.filter === "Reading"
                ? "--selected"
                : ""
            } teacher-portal__student--subject`}
            onClick={() =>
              setFilters((prev: FiltersType[]) => [
                ...prev.filter((individual) => individual._id !== student._id),
                { _id: student._id, filter: "Reading" },
              ])
            }
          >
            Reading
          </div>
          <div
            className={`teacher-portal__student--subject--math${
              filters.find((individual) => individual._id === student._id)
                ?.filter === "Math"
                ? "--selected"
                : ""
            } teacher-portal__student--subject`}
            onClick={() =>
              setFilters((prev: FiltersType[]) => [
                ...prev.filter((individual) => individual._id !== student._id),
                { _id: student._id, filter: "Math" },
              ])
            }
          >
            Math
          </div>
          <div
            className={`teacher-portal__student--subject--english${
              filters.find((individual) => individual._id === student._id)
                ?.filter === "English"
                ? "--selected"
                : ""
            } teacher-portal__student--subject`}
            onClick={() =>
              setFilters((prev: FiltersType[]) => [
                ...prev.filter((individual) => individual._id !== student._id),
                { _id: student._id, filter: "English" },
              ])
            }
          >
            English
          </div>
        </div>
        <div className="teacher-portal__student--parent-container">
          <div className="teacher-portal__student--parent-title">
            Parent's Email:
          </div>
          {parentEmail ? (
            <div>
              <div className="teacher-portal__student--parent-email-container">
                {parentEmail}
              </div>

              <div className="teacher-portal__student--parent-email-copy-container">
                <div className="teacher-portal__student--parent-email-copy-text">
                  Copy <i className="fa fa-copy" />
                </div>
              </div>
            </div>
          ) : (
            <div className="teacher-portal__student--parent-not-connected">
              No Parent Connected
            </div>
          )}
        </div>
      </div>
      <div className="teacher-portal__student--row">
        <div className="teacher-portal__student--last-active-container">
          <div className="teacher-portal__student--last-active-text">
            Last Active:{" "}
          </div>
          <div className="teacher-portal__student--last-active-days-ago">
            {student.last_active
              ? moment(student.last_active).fromNow()
              : "Inactive"}
          </div>
        </div>
        <div className="teacher-portal__student--tags-container">
          <div className="teacher-portal__student--tag">At Risk</div>
        </div>
      </div>
      {filters.find((individual) => individual._id === student._id)?.filter ===
      "Reading" ? (
        <Reading
          activeClassroom={activeClassroom}
          student={student}
          studentAssignment={studentAssignment}
        />
      ) : filters.find((individual) => individual._id === student._id) //ALL SUBJECTS
          ?.filter === "All" ? (
        <All />
      ) : filters.find((individual) => individual._id === student._id) //ALL SUBJECTS
          ?.filter === "Math" ? (
        //OTHER SUBJECTS
        <Math />
      ) : filters.find((individual) => individual._id === student._id) //ALL SUBJECTS
          ?.filter === "English" ? (
        <English />
      ) : (
        <div className="teacher-portal__student--row">
          <div>Other</div>
        </div>
      )}
    </div>
  );
};

export default StudentRow;
