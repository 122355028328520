import React, { useRef, useState } from "react";
import { useDisplayData } from "../../../Hooks/useDisplayData";
import { Note, NoteTagColors, NoteTagColorType } from "../../../Models";
import { generateID, GetBaseURL } from "../../../Utils";
import ColorPicker from "../ColorPicker";

const Notes: React.FC = () => {
  const { displayData, createNoteFromModal, showNotesModal } = useDisplayData();
  const [modifiedNotes, setModifiedNotes] = useState(displayData.studentNotes);
  const [activeNote, setActiveNote] = useState<Note | null>(null);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [deletion, setDeletion] = useState(false);
  const [colorPicker, setColorPicker] = useState({ show: false, index: -1 });
  const [showX, setShowX] = useState({ show: false, index: -1 });
  const [editingContent, setEditingContent] = useState(false);
  const newTagRef = useRef<HTMLDivElement>(null);

  const selectNewColor = (tagIndex: number, newColorAbbreviation: string) => {
    let notesToModify = modifiedNotes;
    notesToModify![activeIndex].tags[tagIndex].color = newColorAbbreviation;
    setModifiedNotes(notesToModify);
    updateNotes();
    setColorPicker({ show: false, index: -1 });
  };

  const createNewNote = async () => {
    setColorPicker({ show: false, index: -1 });
    setDeletion(false);
    createNoteFromModal({
      date_updated: new Date(Date.now()),
      tags: [],
      internal: true,
      title: "No Title",
      content: "No Content",
    });
    setModifiedNotes((prev: Note[] | undefined) => [
      {
        date_updated: new Date(Date.now()),
        tags: [],
        internal: true,
        title: "No Title",
        content: "No Content",
      },
      ...prev!,
    ]);
    const request = await fetch(
      `${GetBaseURL()}/api/classrooms/createNote?key=${
        process.env.REACT_APP_PERSONAL_AUTH_KEY
      }`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          classroom_id: displayData.activeClassroom?._id,
          student_id: displayData.student?._id,
        }),
      }
    );
    const response = await request.json();
  };

  const updateNotes = async () => {
    showNotesModal(displayData.student!, modifiedNotes!);
    const request = await fetch(
      `${GetBaseURL()}/api/classrooms/updateNotes?key=${
        process.env.REACT_APP_PERSONAL_AUTH_KEY
      }`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          classroom_id: displayData.activeClassroom?._id,
          student_id: displayData.student?._id,
          updated_notes: modifiedNotes,
        }),
      }
    );
    const response = await request.json();
  };

  return (
    <div className="notes-modal">
      <div className="notes-modal__header">
        <div className="notes-modal__header--name-dropdown-wrapper">
          <div className="notes-modal__header--title">
            {displayData.student?.user_data.name}'s Notes
          </div>
          <div className="notes-modal__header--dropdown-wrapper"></div>
        </div>
        <div
          className="notes-modal__header--create-new"
          onClick={() => createNewNote()}
        >
          Create New
        </div>
      </div>
      <div className="notes-modal__body">
        <div className="notes-modal__body--left-container">
          <div className="notes-modal__body--notes-list">
            {modifiedNotes?.map((note, index) => (
              <React.Fragment key={generateID()}>
                <div
                  className="notes-modal__body--list-cell"
                  onClick={() => {
                    setColorPicker({ show: false, index: -1 });
                    setDeletion(false);
                    if (activeIndex === -1 || activeIndex !== index) {
                      setActiveNote(note);
                      setActiveIndex(index);
                    } else {
                      setActiveNote(null);
                      setActiveIndex(-1);
                    }
                  }}
                >
                  <div className="notes-modal__body--cell-header">
                    <div className="notes-modal__body--cell-date-label">
                      <div className="notes-modal__body--cell-date">
                        {new Date(note.date_updated).toLocaleDateString(
                          "en-us",
                          {
                            month: "long",
                            day: "numeric",
                            year: "numeric",
                          }
                        )}
                      </div>
                    </div>
                    <div className="notes-modal__body--cell-tags">
                      {note.tags.slice(0, 2).map((tag) => (
                        <React.Fragment key={generateID()}>
                          <div
                            className={`notes-modal__body--cell-tag ${tag.color}`}
                          ></div>
                        </React.Fragment>
                      ))}
                      {note.tags.length > 2 && "..."}
                    </div>
                  </div>
                  <div className="notes-modal__body--cell-label-internal">
                    {note.internal ? "Internal Note" : "Shared with Parent"}
                  </div>
                  <div className="notes-modal__body--cell-title">
                    {note.title}
                  </div>
                  <div className="notes-modal__body--cell-note-preview">
                    {note.content.slice(0, 59)}
                    {note.content.length > 60 && "..."}
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className="notes-modal__body--right-container">
          {activeNote ? (
            <>
              <div className="notes-modal__body--note-header">
                <div className="notes-modal__body--note-tags-wrapper">
                  {activeNote.tags.map((tag, index) => (
                    <React.Fragment key={generateID()}>
                      <div
                        onClick={() => setColorPicker({ show: true, index })}
                        // onBlur={(e) => {
                        //   setEditingContent(false);
                        //   let notesToModify = modifiedNotes;
                        //   notesToModify![activeIndex].tags[index].text = e
                        //     .target.textContent
                        //     ? e.target.textContent.slice(
                        //         0,
                        //         e.target.textContent.length - 1
                        //       )
                        //     : "New Tag";
                        //   setModifiedNotes(notesToModify);
                        //   updateNotes();

                        //   setColorPicker({ show: true, index });
                        // }}
                        className={`notes-modal__body--note-tags ${tag.color}`}
                        // onKeyDown={(e) => {
                        //   if (e.code === "Enter") {
                        //     e.currentTarget.blur();
                        //   }
                        // }}
                      >
                        {tag.text}
                        {/* {showX.index === index && showX.show && (
                          <div
                          className="notes-modal__body--note-tags-delete"
                          onClick={(e) => {
                            e.stopPropagation();
                            let notesToModify = modifiedNotes;
                            notesToModify![activeIndex].tags = notesToModify![
                              activeIndex
                            ].tags.filter(
                              (tag, tagIndex) => tagIndex !== index
                              );
                              setModifiedNotes(notesToModify);
                              updateNotes();
                            }}
                            >
                            x
                            </div>
                          )} */}
                      </div>
                      {colorPicker.index === index && colorPicker.show && (
                        <ColorPicker
                          tagIndex={index}
                          selectColor={selectNewColor}
                          currentText={tag.text}
                          modifiedNotes={modifiedNotes}
                          activeIndex={activeIndex}
                          setModifiedNotes={setModifiedNotes}
                          updateNotes={updateNotes}
                          setColorPicker={setColorPicker}
                        />
                      )}
                    </React.Fragment>
                  ))}

                  <div
                    className="notes-modal__body--note-tags-add"
                    onClick={() => {
                      let notesToModify = modifiedNotes;
                      notesToModify![activeIndex].tags.push({
                        color:
                          NoteTagColors[
                            Math.floor(Math.random() * NoteTagColors.length)
                          ].abbreviation,
                        text: "New Tag",
                      });

                      setModifiedNotes(notesToModify);
                      updateNotes();
                    }}
                  >
                    Add +
                  </div>
                </div>
                <div
                  className="notes-modal__body--note-delete"
                  onClick={() => {
                    if (deletion) {
                      let notesToModify = modifiedNotes;
                      notesToModify!.splice(activeIndex, 1);

                      setModifiedNotes(notesToModify);
                      setActiveIndex(-1);
                      setActiveNote(null);
                      updateNotes();
                      setDeletion(false);
                    } else {
                      setDeletion(true);
                    }
                  }}
                >
                  {deletion ? "Confirm Deletion" : "Delete Note"}
                </div>
              </div>
              <div className="notes-modal__body--note-date-shared-row">
                <div className="notes-modal__body--note-date">
                  {new Date(activeNote.date_updated).toLocaleDateString(
                    "en-us",
                    {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    }
                  )}
                </div>
                <div className="notes-modal__body--note-dropdown">
                  <div className="notes-modal__body--note-dropdown-text">
                    {activeNote.internal
                      ? "Internal Note"
                      : "Shared With Parent"}
                  </div>
                  <div className="notes-modal__body--note-dropdown-arrow">
                    <i className="fas fa-chevron-down"></i>
                  </div>
                </div>
              </div>
              <div className="notes-modal__body--note-label-copy-row">
                <div className="notes-modal__body--note-label">Note:</div>
                <div className="notes-modal__body--note-copy">
                  Copy <i className="fa fa-copy" />
                </div>
              </div>
              <div className="notes-modal__body--note-body-wrapper">
                <div className="notes-modal__body--note-body-title-wrap">
                  <div className="notes-modal__body--note-body-title-label">
                    Title:
                  </div>
                  <div
                    className="notes-modal__body--note-body-title"
                    contentEditable
                    onBlur={(e) => {
                      if (
                        e.target.textContent !==
                        modifiedNotes![activeIndex].title
                      ) {
                        let notesToModify = modifiedNotes;
                        notesToModify![activeIndex].title = e.target.textContent
                          ? e.target.textContent
                          : "No Title";

                        setModifiedNotes(notesToModify);
                        updateNotes();
                      }
                    }}
                  >
                    {activeNote.title}
                  </div>
                </div>

                <div
                  className="notes-modal__body--note-body-text"
                  contentEditable
                  onBlur={(e) => {
                    if (
                      e.target.textContent !==
                      modifiedNotes![activeIndex].content
                    ) {
                      let notesToModify = modifiedNotes;
                      notesToModify![activeIndex].content = e.target.textContent
                        ? e.target.textContent
                        : "No Content";

                      setModifiedNotes(notesToModify);
                      updateNotes();
                    }
                  }}
                >
                  {activeNote.content}
                </div>

                <div className="notes-modal__body--note-body-report-card"></div>
              </div>
            </>
          ) : modifiedNotes && modifiedNotes.length > 0 ? (
            <div>Select a note to begin</div>
          ) : (
            <div>Create a new note to begin</div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Notes;
