const crypto = require("crypto");
const encryptionType = "aes-256-cbc";
const encryptionEncoding = "base64";
const bufferEncryption = "utf-8";
class AesEncryption {
  AesKey!: string;
  AesIV!: string;
  init() {
    this.AesKey = "FEVBAWQXTWUFSPYMUOCGSJSGMSDVWOYF";
    this.AesIV = "DOFSPLGDZNUMOPEO";
  }

  encrypt(val: string): string {
    const key = Buffer.from(
      "FEVBAWQXTWUFSPYMUOCGSJSGMSDVWOYF",
      bufferEncryption
    );
    const iv = Buffer.from("DOFSPLGDZNUMOPEO", bufferEncryption);
    const cipher = crypto.createCipheriv(encryptionType, key, iv);
    let encrypted = cipher.update(val, bufferEncryption, encryptionEncoding);
    encrypted += cipher.final(encryptionEncoding);
    return encrypted;
  }

  decrypt(base64String: string): any {
    const buff = Buffer.from(base64String, encryptionEncoding);
    const key = Buffer.from(
      "FEVBAWQXTWUFSPYMUOCGSJSGMSDVWOYF",
      bufferEncryption
    );
    const iv = Buffer.from("DOFSPLGDZNUMOPEO", bufferEncryption);
    const decipher = crypto.createDecipheriv(encryptionType, key, iv);
    const deciphered = decipher.update(buff) + decipher.final();

    return deciphered;
  }
}

const NewEncryption = new AesEncryption();

export default NewEncryption;
