import React from "react";
import { Link } from "react-router-dom";
import Modal from "../Components/Partials/Modal";
import { useDisplayData } from "../Hooks/useDisplayData";
import { useUserData } from "../Hooks/useUserData";
import MoonRockHeader from "../Components/MoonRockHeader";

const Subscription: React.FC = () => {
  const { userData } = useUserData();
  const { displayData, showLoginModal } = useDisplayData();

  return (
    <div>
      <div className="subscription">
        <MoonRockHeader />

        <div className="subscription__banner">
          <div className="subscription__banner--img-holder">
            <img
              src="https://moonrock-images.s3.us-east-2.amazonaws.com/popcorn.png"
              alt="Project MoonRock"
              className="subscription__banner--img"
            />
          </div>

          <div className="subscription__banner--header"></div>
        </div>

        <div className="subscription__main-container">
          <div className="subscription__toggle">
            <div className="subscription__toggle--monthly subscription__toggle--selected">
              Monthly
            </div>
            <div className="subscription__toggle--yearly">Yearly</div>
          </div>

          <div className="subscription__perks-card">
            <div className="subscription__perks-card--header">
              <div className="subscription__perks-card--asteroid-holder">
                <img
                  src="https://moonrock-images.s3.us-east-2.amazonaws.com/asteroid_gold_impact_3.png"
                  alt="Project MoonRock"
                  className="subscription__perks-card--asteroid-img"
                />
              </div>
              <div className="subscription__perks-card--title-holder">
                Project MoonRock Membership
              </div>
              <div className="subscription__perks-card--pickaxe-holder">
                <img
                  src="https://moonrock-images.s3.us-east-2.amazonaws.com/asteroid_gold_impact_3.png"
                  alt="Project MoonRock"
                  className="subscription__perks-card--pickaxe-img"
                />
              </div>
            </div>
            <div className="subscription__perks-card--price-container">
              <div className="subscription__perks-card--price">
                <span className="subscription__perks-card--dollar-sign">$</span>
                9.99
              </div>
              <div className="subscription__perks-card--per-month">
                per month
              </div>
            </div>
            <div className="subscription__perks-card--kids">
              <div className="subscription__perks-card--kids-label">
                Benefits for your kids:
              </div>
            </div>
            <div className="subscription__perks-card--parents">
              <div className="subscription__perks-card--parents-label">
                Benefits for parents:
              </div>
            </div>
            <div className="subscription__perks-card--member-button">
              <div className="subscription__perks-card--buy-membership">
                Buy Membership
              </div>
            </div>
          </div>

          <div className="subscription__right-card"></div>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
