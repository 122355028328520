import React, { useState } from "react";
import { useDisplayData } from "../../../Hooks/useDisplayData";
import { useUserData } from "../../../Hooks/useUserData";
import { ReadingLevels, StudentData } from "../../../Models";
import { generateID, GetBaseURL } from "../../../Utils";

const ChangeReadingLevel: React.FC = () => {
  const { displayData, hideModal } = useDisplayData();
  const { setUser, userData } = useUserData();
  const [showReadingLevelAnswer, setShowReadingLevelAnswer] = useState(false);
  return (
    <div className="modal__change-reading-level">
      <div className="modal__change-reading-level--prompt">{`Select a new reading level for ${displayData.student?.user_data.name}`}</div>
      <div
        className="modal__change-reading-level--how-to-test"
        onClick={() => setShowReadingLevelAnswer(!showReadingLevelAnswer)}
      >
        How do I test what reading level my student is at?
      </div>
      {showReadingLevelAnswer && (
        <div className="modal__change-reading-level--answer">
          <div className="modal__change-reading-level--answer-step">
            Step 1) Pick a level you think your student is at.
          </div>
          <div className="modal__change-reading-level--answer-step">
            Step 2) Ask your student to read the story, do not help them with
            any of it.
          </div>
          <div className="modal__change-reading-level--answer-step">
            Step 3) If they miss more than 1 out of every 10 words, the story is
            too hard and you should try an easier level.
          </div>
          <div className="modal__change-reading-level--answer-step">
            Step 4) Keep trying easier levels until your student is at a level
            where they can read 90% of the words on their own. That is their
            current reading level.
          </div>
        </div>
      )}
      <div className="modal__change-reading-level--levels-container">
        {ReadingLevels.map((letter, index) => (
          <React.Fragment key={generateID()}>
            <div
              className={`modal__change-reading-level--level-row ${
                letter ===
                (displayData.student?.user_data as StudentData).reading_level
                  ? "modal__change-reading-level--selected"
                  : ""
              }`}
              onClick={async () => {
                const request = await fetch(
                  `${GetBaseURL()}/api/users/updateReadingLevel?key=${
                    process.env.REACT_APP_PERSONAL_AUTH_KEY
                  }`,
                  {
                    method: "PATCH",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                      child_id: displayData.student?._id,
                      user_id: userData._id,
                      reading_level: letter,
                    }),
                  }
                );
                const response = await request.json();
                if (request.status === 200) {
                  setUser(userData._id);
                } else {
                  console.log(response.message);
                }
                hideModal();
              }}
            >
              <div className="modal__change-reading-level--levels-label">
                {index < 2
                  ? "Kindergarten"
                  : index === 2
                  ? "Kindergarten/Grade 1"
                  : index < 9
                  ? "Grade 1"
                  : index < 13
                  ? "Grade 2"
                  : index < 16
                  ? "Grade 3"
                  : index < 19
                  ? "Grade 4"
                  : index < 22
                  ? "Grade 5"
                  : index < 25
                  ? "Grade 6"
                  : "Grades 7-8"}
              </div>
              <span
                className={`level-${letter} modal__change-reading-level--level `}
              >
                {letter}
              </span>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
export default ChangeReadingLevel;
