import React from "react";

const LessonPlans: React.FC = () => {
  return (
    <div className="teacher-portal__messages">
      <div>Coming Soon!</div>
    </div>
  );
};

export default LessonPlans;
