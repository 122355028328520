import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { DisplayContextProvider } from "./Context/DisplayContext";
import { StoryContextProvider } from "./Context/StoryContext";
import { UserContextProvider } from "./Context/UserContext";
import Home from "./Pages/Home";
import Markup from "./Pages/Markup";
import Subscription from "./Pages/Subcription";

function App() {
  return (
    <div className="App">
      <UserContextProvider>
        <DisplayContextProvider>
          <StoryContextProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route
                  path="/moonrock-subscription"
                  element={<Subscription />}
                />

                <Route path="/markup/:assignmentInfo" element={<Markup />} />
              </Routes>
            </BrowserRouter>
          </StoryContextProvider>
        </DisplayContextProvider>
      </UserContextProvider>
    </div>
  );
}

export default App;
