import React from "react";
import { useDisplayData } from "../../../Hooks/useDisplayData";
import { useUserData } from "../../../Hooks/useUserData";
import { GetBaseURL } from "../../../Utils";

interface AddClassroomProps {
  setError: (
    value: React.SetStateAction<{
      isActive: boolean;
      type: string;
      message: string;
    }>
  ) => void;
}

const AddClassroom: React.FC<AddClassroomProps> = ({ setError }) => {
  const { hideModal } = useDisplayData();
  const { userData, setUser } = useUserData();

  const handleAddClassroom = async (e: any) => {
    e.preventDefault();
    if (e.target.name.value !== "") {
      const request = await fetch(
        `${GetBaseURL()}/api/classrooms/create?key=${
          process.env.REACT_APP_PERSONAL_AUTH_KEY
        }`,
        {
          method: "POST",
          body: JSON.stringify({
            teacher_id: userData._id,
            name: e.target.name.value,
          }),
          headers: { "Content-Type": "application/json" },
        }
      );
      const response = await request.json();
      if (request.status === 200) {
        setUser(userData._id);
        hideModal();
      } else {
        setError({ type: "error", isActive: true, message: response.message });
      }
    } else {
      setError({
        isActive: true,
        type: "error",
        message: "Please create a name for your class.",
      });
    }
  };

  return (
    <div className="modal__add-classroom">
      <div className="modal__prompt">Add a new classroom</div>
      <form onSubmit={handleAddClassroom} className="modal__form">
        <input
          className="modal__input"
          type="text"
          placeholder="Class Name"
          name="name"
        />
        <input className="modal__submit" type="submit" value="Submit"></input>
      </form>
    </div>
  );
};
export default AddClassroom;
