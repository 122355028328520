import React, { useRef, useState } from "react";
import { useDisplayData } from "../../../Hooks/useDisplayData";
import { useStories } from "../../../Hooks/useStories";
import { useUserData } from "../../../Hooks/useUserData";
import { generateID, GetBaseURL } from "../../../Utils";
import { QuickAssignStory } from "../Modal";
import StoryRow from "../StoryRow";
import StoryRowTop from "../StoryRowTop";

interface QuickAssignProps {
  setError: (
    value: React.SetStateAction<{
      isActive: boolean;
      type: string;
      message: string;
    }>
  ) => void;
}

const QuickAssign: React.FC<QuickAssignProps> = ({ setError }) => {
  const { displayData, hideModal } = useDisplayData();
  const { storyData } = useStories();
  const { userData, setUser } = useUserData();
  const [selectedDate, setSelectedDate] = useState<Date>(
    new Date(new Date(Date.now()).setDate(new Date().getDate() + 1))
  );
  const dateTimeRef = useRef<HTMLInputElement>(null);
  const [sorter, setSorter] = useState("");
  const [ascending, setAscending] = useState(true);
  const [quickAssignStory, setQuickAssignStory] =
    useState<QuickAssignStory | null>(null);
  return (
    <>
      <div className="modal__quick-assign">
        <div className="modal__prompt">
          Choose a story to assign to{" "}
          {displayData.student
            ? displayData.student.user_data.name
            : `every student in ${displayData.activeClassroom?.name}`}
        </div>
        <div className="modal__due-date--container">
          <div className="modal__due-date--text">Due Date:</div>
          <div className="modal__due-date--option">
            {selectedDate?.toLocaleDateString()}{" "}
            {selectedDate?.toLocaleTimeString()}
          </div>

          <input
            type="datetime-local"
            defaultValue={new Date(
              new Date(Date.now()).setDate(new Date().getDate() + 1)
            )
              .toISOString()
              .split(":")
              .filter((datePart, index) => index < 2)
              .join(":")}
            min={new Date(
              new Date(Date.now()).setDate(new Date().getDate() + 1)
            )
              .toISOString()
              .split(":")
              .filter((datePart, index) => index < 2)
              .join(":")}
            ref={dateTimeRef}
            onChange={(e) => {
              setSelectedDate(new Date(e.target.value));
            }}
          />
        </div>
        <StoryRowTop
          sorter={sorter}
          setSorter={setSorter}
          ascending={ascending}
          setAscending={setAscending}
        />
        <div className="modal__stories-container">
          {storyData.stories
            .sort((first, second) => {
              switch (sorter) {
                case "title":
                  if (ascending) {
                    return first.title.localeCompare(second.title);
                  } else {
                    return second.title.localeCompare(first.title);
                  }
                case "level":
                  if (ascending) {
                    return first.level.localeCompare(second.level);
                  } else {
                    return second.level.localeCompare(first.level);
                  }

                default:
                  if (ascending) {
                    return first._id!.localeCompare(second._id!);
                  } else {
                    return second._id!.localeCompare(first._id!);
                  }
              }
            })
            .map((story) => (
              <React.Fragment key={generateID()}>
                <StoryRow
                  story_id={story._id}
                  highlight={quickAssignStory?.id === story._id}
                  setQuickAssignStory={setQuickAssignStory}
                  quickAssignStory={quickAssignStory}
                />
              </React.Fragment>
            ))}
        </div>
      </div>
      {selectedDate && (
        <div className="modal__quick-assign--confirm">
          <div className="modal__prompt-assigned">
            <div>
              Book:{" "}
              <span
                className="modal__assigned-label"
                style={quickAssignStory ? undefined : { color: "red" }}
              >
                {quickAssignStory ? quickAssignStory.title : "Not Selected"}
              </span>{" "}
            </div>
            <div>
              Due:{" "}
              <span className="modal__assigned-label">
                {selectedDate.toDateString()}
              </span>
            </div>
            <div>
              Student:{" "}
              <span className="modal__assigned-label">
                {" "}
                {displayData.student
                  ? displayData.student.user_data.name
                  : `all students in ${displayData.activeClassroom?.name}`}{" "}
              </span>
            </div>
          </div>
          <div
            style={
              quickAssignStory
                ? undefined
                : { pointerEvents: "none", background: "lightgray" }
            }
            className="modal__submit"
            onClick={async () => {
              const request = await fetch(
                `${GetBaseURL()}/api/classrooms/assignStory?key=${
                  process.env.REACT_APP_PERSONAL_AUTH_KEY
                }`,
                {
                  method: "PATCH",
                  body: JSON.stringify({
                    classroom_id: displayData.activeClassroom?._id,
                    story_id: quickAssignStory!.id,
                    student_id: displayData.student
                      ? displayData.student._id
                      : undefined,
                    due_date: selectedDate,
                  }),
                  headers: { "Content-Type": "application/json" },
                }
              );
              const response = await request.json();
              if (request.status === 200) {
                setUser(userData._id);
                hideModal();
              } else {
                setError({
                  type: "error",
                  isActive: true,
                  message: response.message,
                });
              }
            }}
          >
            Assign
          </div>
        </div>
      )}
    </>
  );
};

export default QuickAssign;
