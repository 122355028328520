import React, { useState, useEffect } from "react";
import { TeacherData, Users } from "../Models";
import { GetBaseURL } from "../Utils";
export interface UserContextProps {
  userData: Users;
  setUserData: (userData: any) => void;
}

const UserContext = React.createContext({} as UserContextProps);

const UserContextProvider = (props: any) => {
  const [userData, setUserData] = useState<Users>({
    _id: "",
    account_type: "teacher",
    user_data: {
      school_district: "",
      name: "",

      email: "",
      classrooms: [],
    },
    subscription_data: {
      plan_type: "free",
      freemium_tallies: [],
    },
    shouldUpdate: false,
    displayTutorial: true,
  });

  const fetchUser = async (id: string) => {
    const request = await fetch(
      `${GetBaseURL()}/api/users/fetchSingle?key=${
        process.env.REACT_APP_PERSONAL_AUTH_KEY
      }`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ id }),
      }
    );
    const response = await request.json();
    if (request.status === 200) {
      setUserData((prev: any) => ({
        ...prev,
        ...response.user[0],
        // user_data: {
        //   ...prev.user_data,
        //   ...response.user[0].user_data,
        //   perm_tracked_words: response.user[0].user_data.tracked_words,
        // },
        shouldUpdate: false,
        updateID: undefined,
      }));
      // console.log(response.user[0]);
    } else {
      console.log(response.message);
    }
  };
  const checkBillingChanges = async () => {
    const request = await fetch(
      `${GetBaseURL()}/api/payments/billingChanges/${`${userData.subscription_data.stripe_customer_id}/${userData._id}`}`,
      { method: "GET", headers: { "Content-Type": "application/json" } }
    );
    const response = await request.json();
    if (request.status === 200) {
      fetchUser(response.user._id);
    } else {
      console.log(response.message);
    }
  };

  useEffect(() => {
    if (userData.shouldUpdate || userData._id === "") {
      if (userData.updateID) {
        fetchUser(userData.updateID);
      } else if (localStorage.getItem("id")) {
        fetchUser(localStorage.getItem("id")!);
      }
    }
  }, [userData.shouldUpdate]);

  useEffect(() => {
    if (userData._id !== "") {
      checkBillingChanges();
    }
  }, [userData._id]);

  const providerValue: UserContextProps = {
    userData,
    setUserData,
  };

  return (
    <UserContext.Provider value={providerValue}>
      {props.children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };
