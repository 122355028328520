import React, { useState } from "react";
import { useDisplayData } from "../../../Hooks/useDisplayData";
import { useStories } from "../../../Hooks/useStories";
import { generateID } from "../../../Utils";
import StoryRow from "../StoryRow";
import StoryRowTop from "../StoryRowTop";

const ToDo: React.FC = () => {
  const { displayData } = useDisplayData();
  const { storyData } = useStories();
  const [sorter, setSorter] = useState("");
  const [ascending, setAscending] = useState(true);
  return (
    <div className="modal__to-do">
      <div className="modal__overdue--heading">
        <div className="modal__overdue--subheading-1 teacher-portal__student--stat-box--to-do-text">
          {displayData.assignment?.student.user_data.name}'s To-Do Assignments
        </div>
        <div className="modal__overdue--subheading-2 teacher-portal__student--stat-box--to-do-value">
          {displayData.numberOfAssignments}
        </div>
      </div>
      <StoryRowTop
        sorter={sorter}
        setSorter={setSorter}
        ascending={ascending}
        setAscending={setAscending}
        addDueDate={true}
      />
      <div className="modal__to-do--assignments">
        {displayData
          .assignment!.assignments.filter(
            (assignment) =>
              new Date(assignment.due_date).valueOf() >
                new Date(Date.now()).valueOf() && !assignment.relevant_data
          )
          .sort((first, second) => {
            let relevantStory1 = storyData.stories.filter(
              (story) => story._id === first.story
            )[0];
            let relevantStory2 = storyData.stories.filter(
              (story) => story._id === second.story
            )[0];

            switch (sorter) {
              case "due date":
                if (ascending) {
                  return (
                    new Date(first.due_date).valueOf() -
                    new Date(second.due_date).valueOf()
                  );
                } else {
                  return (
                    new Date(second.due_date).valueOf() -
                    new Date(first.due_date).valueOf()
                  );
                }
              case "title":
                if (ascending) {
                  return relevantStory1.title.localeCompare(
                    relevantStory2.title
                  );
                } else {
                  return relevantStory2.title.localeCompare(
                    relevantStory1.title
                  );
                }
              case "level":
                if (ascending) {
                  return relevantStory1.level.localeCompare(
                    relevantStory2.level
                  );
                } else {
                  return relevantStory2.level.localeCompare(
                    relevantStory1.level
                  );
                }

              default:
                if (ascending) {
                  return (
                    new Date(first.due_date).valueOf() -
                    new Date(second.due_date).valueOf()
                  );
                } else {
                  return (
                    new Date(second.due_date).valueOf() -
                    new Date(first.due_date).valueOf()
                  );
                }
            }
          })
          .map((assignment) => (
            <React.Fragment key={generateID()}>
              <div className="modal__to-do--assignment-row">
                <StoryRow
                  story_id={
                    storyData.stories.find(
                      (story) => story._id === assignment.story
                    )!._id
                  }
                  highlight={false}
                  dueDate={new Date(assignment.due_date)}
                />
              </div>
            </React.Fragment>
          ))}
      </div>
    </div>
  );
};

export default ToDo;
