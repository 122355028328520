import { useContext } from "react";
import { Display, DisplayContext } from "../Context/DisplayContext";
import {
  Assignment,
  Classrooms,
  Note,
  Story,
  StudentData,
  TrackedWords,
  Users,
} from "../Models";

export const useDisplayData = () => {
  const { displayData, setDisplayData } = useContext(DisplayContext);

  const showModal = () => {
    setDisplayData((prev: Display) => ({ ...prev, showModal: true }));
  };

  const showLoginModal = (redirectToHome?: boolean) => {
    setDisplayData((prev: Display) => ({
      ...prev,
      showModal: true,
      login: true,
      register: false,
      addClassroom: false,
      addStudent: false,
      quickAssign: false,
      showCompleted: false,
      showToDo: false,
      showOverdue: false,
      changeReadingLevel: false,
      trackedWords: false,
      notes: false,
    }));
  };

  const showRegisterModal = (redirectToHome?: boolean) => {
    setDisplayData((prev: Display) => ({
      ...prev,
      showModal: true,
      register: true,
      login: false,
      addClassroom: false,
      addStudent: false,
      quickAssign: false,
      showCompleted: false,
      showToDo: false,
      showOverdue: false,
      changeReadingLevel: false,
      trackedWords: false,
      notes: false,
    }));
  };

  const showAddClassroomModal = () => {
    setDisplayData((prev: Display) => ({
      ...prev,
      showModal: true,
      addClassroom: true,
      addStudent: false,
      login: false,
      register: false,
      quickAssign: false,
      showCompleted: false,
      showToDo: false,
      showOverdue: false,
      changeReadingLevel: false,
      trackedWords: false,
      notes: false,
    }));
  };

  const showAddStudentModal = (activeClassroom: Classrooms) => {
    setDisplayData((prev: Display) => ({
      ...prev,
      showModal: true,
      addClassroom: false,
      addStudent: true,
      login: false,
      register: false,
      quickAssign: false,
      showCompleted: false,
      showToDo: false,
      showOverdue: false,
      changeReadingLevel: false,
      trackedWords: false,
      notes: false,
      activeClassroom,
    }));
  };

  const showQuickAssignModal = (
    activeClassroom?: Classrooms,
    student?: Users
  ) => {
    setDisplayData((prev: Display) => ({
      ...prev,
      showModal: true,
      addClassroom: false,
      addStudent: false,
      login: false,
      register: false,
      quickAssign: true,
      showCompleted: false,
      showToDo: false,
      showOverdue: false,
      changeReadingLevel: false,
      trackedWords: false,
      notes: false,
      activeClassroom,
      student,
    }));
  };

  const showCompletedModal = (
    assignment?: Assignment,
    numberOfAssignments?: number
  ) => {
    setDisplayData((prev: Display) => ({
      ...prev,
      showModal: true,
      addClassroom: false,
      addStudent: false,
      login: false,
      register: false,
      quickAssign: false,
      showCompleted: true,
      showToDo: false,
      showOverdue: false,
      changeReadingLevel: false,
      trackedWords: false,
      notes: false,
      assignment,
      numberOfAssignments,
    }));
  };

  const setActiveAssignmentInContext = (assignment: Assignment) => {
    setDisplayData((prev: Display) => ({
      ...prev,
      assignment,
    }));
  };

  const showToDoModal = (
    assignment?: Assignment,
    numberOfAssignments?: number
  ) => {
    setDisplayData((prev: Display) => ({
      ...prev,
      showModal: true,
      addClassroom: false,
      addStudent: false,
      login: false,
      register: false,
      quickAssign: false,
      showCompleted: false,
      showToDo: true,
      showOverdue: false,
      changeReadingLevel: false,
      trackedWords: false,
      notes: false,
      assignment,
      numberOfAssignments,
    }));
  };

  const showOverdueModal = (
    assignment?: Assignment,
    numberOfAssignments?: number
  ) => {
    setDisplayData((prev: Display) => ({
      ...prev,
      showModal: true,
      addClassroom: false,
      addStudent: false,
      login: false,
      register: false,
      quickAssign: false,
      showCompleted: false,
      showToDo: false,
      showOverdue: true,
      changeReadingLevel: false,
      trackedWords: false,
      notes: false,
      assignment,
      numberOfAssignments,
    }));
  };

  const showChangeReadingLevelModal = (student: Users) => {
    setDisplayData((prev: Display) => ({
      ...prev,
      showModal: true,
      addClassroom: false,
      addStudent: false,
      login: false,
      register: false,
      quickAssign: false,
      showCompleted: false,
      showToDo: false,
      showOverdue: false,
      changeReadingLevel: true,
      trackedWords: false,
      notes: false,
      student,
    }));
  };

  const showTrackedWordsModal = (student: Users) => {
    setDisplayData((prev: Display) => ({
      ...prev,
      showModal: true,
      addClassroom: false,
      addStudent: false,
      login: false,
      register: false,
      quickAssign: false,
      showCompleted: false,
      showToDo: false,
      showOverdue: false,
      changeReadingLevel: false,
      trackedWords: true,
      notes: false,
      student,
    }));
  };

  const showNotesModal = (student: Users, notes: Note[]) => {
    setDisplayData((prev: Display) => ({
      ...prev,
      showModal: true,
      addClassroom: false,
      addStudent: false,
      login: false,
      register: false,
      quickAssign: false,
      showCompleted: false,
      showToDo: false,
      showOverdue: false,
      changeReadingLevel: false,
      trackedWords: false,
      notes: true,
      student,
      studentNotes: notes,
    }));
  };

  const createNoteFromModal = (note: Note) => {
    setDisplayData((prev: Display) => ({
      ...prev,
      studentNotes: [note, ...prev.studentNotes!],
    }));
  };

  const setSettingAssignment = (bool: boolean) => {
    setDisplayData((prev: Display) => ({
      ...prev,
      settingAssignment: bool,
    }));
  };

  const setActiveClassroomInContext = (
    activeClassroom: Classrooms,
    keepModal?: boolean
  ) => {
    setDisplayData((prev: Display) => ({
      ...prev,
      showModal: keepModal ? keepModal : false,
      activeClassroom,
    }));
  };

  const hideModal = () => {
    setDisplayData((prev: Display) => ({
      ...prev,
      showModal: false,
      manageSubscription: false,
      login: false,
      register: false,
      addChildProfile: false,
      addClassroom: false,
      inviteTeacher: false,
      stripe: false,
      signIntoClass: false,
      childProfiles: false,
      nextStory: false,
      trackedWords: false,
      storiesRead: false,
      recentlyLearned: false,
      changeReadingLevel: false,
      changeGrade: false,
    }));
  };

  return {
    displayData,
    showModal,
    showLoginModal,
    showAddClassroomModal,
    showAddStudentModal,
    showRegisterModal,
    showQuickAssignModal,
    showCompletedModal,
    showToDoModal,
    showOverdueModal,
    showChangeReadingLevelModal,
    showTrackedWordsModal,
    showNotesModal,
    setSettingAssignment,
    setActiveClassroomInContext,
    setActiveAssignmentInContext,
    createNoteFromModal,
    hideModal,
  };
};
