import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useDisplayData } from "../../Hooks/useDisplayData";
import { useStories } from "../../Hooks/useStories";
import { useUserData } from "../../Hooks/useUserData";
import {
  MarkedUpWord,
  Mistakes,
  MistakesType,
  Story,
  StudentAssignment,
} from "../../Models";
import { generateID, GetBaseURL } from "../../Utils";
import MistakeOptionsDropdown from "./MistakeOptionsDropdown";

type CompletedAssignmentDetailsProps = {
  view?: boolean;
  assignment: StudentAssignment;
  setExpandedAssignments: (value: React.SetStateAction<string[]>) => void;
};

type StateBool = { notes: boolean; manualScore: boolean };
export type ManualMarkupState = {
  isActive: boolean;
  markedUpWords: MarkedUpWord[];
};

const CompletedAssignmentDetails: React.FC<CompletedAssignmentDetailsProps> = ({
  view,
  assignment,
  setExpandedAssignments,
}) => {
  const { storyData } = useStories();
  const { displayData, setActiveClassroomInContext } = useDisplayData();
  const { userData, setUser } = useUserData();
  const [notes, setNotes] = useState(assignment.teacher_data?.notes);
  const [score, setScore] = useState(assignment.teacher_data?.manual_score);
  const [storyDetails, setStoryDetails] = useState<Story | undefined>(
    undefined
  );
  const [hover, setHover] = useState<StateBool>({
    notes: false,
    manualScore: false,
  });
  const [clicked, setClicked] = useState<StateBool>({
    notes: false,
    manualScore: false,
  });
  const [manualMarkup, setManualMarkup] = useState<ManualMarkupState>({
    isActive: assignment.teacher_data?.markup ? true : false,
    markedUpWords: assignment.teacher_data?.markup
      ? assignment.teacher_data.markup
      : [],
  });
  const [donePosting, setDonePosting] = useState(false);
  const [mistakes, setMistakes] = useState<Mistakes>({
    mispronunciations: 0,
    omissions: 0,
    repetitions: 0,
    self_corrections: 0,
    other: 0,
  });
  const [mistakeOptions, setMistakeOptions] = useState({
    show: false,
    pageIndex: 0,
    lineIndex: 0,
    wordIndex: 0,
  });
  const [focus, setFocus] = useState(false);

  useLayoutEffect(() => {
    if (storyData && storyData.stories.length > 0) {
      setStoryDetails(
        storyData.stories.find((story) => story._id === assignment.story)
      );
    }
  }, [storyData.stories]);

  // useLayoutEffect(() => {
  //   if (assignment.teacher_data && assignment.teacher_data.markup) {
  //     const manualMarkupWords = manualMarkup.markedUpWords.map(
  //       (markedUpWord) => markedUpWord.word
  //     );
  //     const manualMarkupWordIndices = manualMarkup.markedUpWords.map(
  //       (markedUpWord) => markedUpWord.word_index
  //     );
  //     const manualMarkupLineIndices = manualMarkup.markedUpWords.map(
  //       (markedUpWord) => markedUpWord.line_index
  //     );
  //     const manualMarkupPageIndices = manualMarkup.markedUpWords.map(
  //       (markedUpWord) => markedUpWord.page_index
  //     );
  //     if (
  //       assignment.teacher_data.markup.every(
  //         (teacherMarkupWord) =>
  //           manualMarkupWords.includes(teacherMarkupWord.word) &&
  //           manualMarkupWordIndices.includes(teacherMarkupWord.word_index) &&
  //           manualMarkupLineIndices.includes(teacherMarkupWord.line_index) &&
  //           manualMarkupPageIndices.includes(teacherMarkupWord.page_index)
  //       )
  //     ) {
  //       setDonePosting(true);
  //     }
  //   }
  // }, [assignment.teacher_data, manualMarkup.markedUpWords]);

  const SubmitData = async (text: string | null, notes: boolean) => {
    if (text && text.trim() !== "") {
      const request = await fetch(
        `${GetBaseURL()}/api/classrooms/submit${
          notes ? "Notes" : "Score"
        }?key=${process.env.REACT_APP_PERSONAL_AUTH_KEY}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(
            notes
              ? {
                  classroom_id: displayData.activeClassroom?._id,
                  student_id: displayData.assignment?.student._id,
                  story_id: assignment.story,
                  notes: text.trim(),
                }
              : {
                  classroom_id: displayData.activeClassroom?._id,
                  student_id: displayData.assignment?.student._id,
                  story_id: assignment.story,
                  score: Number(text.trim()),
                }
          ),
        }
      );
      const response = await request.json();
      if (request.status === 200) {
        let newActiveClassroom = displayData.activeClassroom!;
        if (assignment.teacher_data) {
          notes
            ? (newActiveClassroom.active_assignments[
                newActiveClassroom?.active_assignments.findIndex(
                  (activeAssignment) =>
                    activeAssignment.student._id ===
                    displayData.assignment!.student._id
                )
              ].assignments[
                newActiveClassroom.active_assignments[
                  newActiveClassroom.active_assignments.findIndex(
                    (activeAssignment) =>
                      activeAssignment.student._id ===
                      displayData.assignment!.student._id
                  )
                ].assignments.findIndex(
                  (studentAssignment) =>
                    studentAssignment.story === assignment.story
                )
              ].teacher_data!.notes = text.trim())
            : (newActiveClassroom.active_assignments[
                newActiveClassroom?.active_assignments.findIndex(
                  (activeAssignment) =>
                    activeAssignment.student._id ===
                    displayData.assignment!.student._id
                )
              ].assignments[
                newActiveClassroom.active_assignments[
                  newActiveClassroom.active_assignments.findIndex(
                    (activeAssignment) =>
                      activeAssignment.student._id ===
                      displayData.assignment!.student._id
                  )
                ].assignments.findIndex(
                  (studentAssignment) =>
                    studentAssignment.story === assignment.story
                )
              ].teacher_data!.manual_score = Number(text.trim()));
        } else {
          notes
            ? (newActiveClassroom.active_assignments[
                newActiveClassroom?.active_assignments.findIndex(
                  (activeAssignment) =>
                    activeAssignment.student._id ===
                    displayData.assignment!.student._id
                )
              ].assignments[
                newActiveClassroom.active_assignments[
                  newActiveClassroom.active_assignments.findIndex(
                    (activeAssignment) =>
                      activeAssignment.student._id ===
                      displayData.assignment!.student._id
                  )
                ].assignments.findIndex(
                  (studentAssignment) =>
                    studentAssignment.story === assignment.story
                )
              ].teacher_data = { notes: text.trim() })
            : (newActiveClassroom.active_assignments[
                newActiveClassroom?.active_assignments.findIndex(
                  (activeAssignment) =>
                    activeAssignment.student._id ===
                    displayData.assignment!.student._id
                )
              ].assignments[
                newActiveClassroom.active_assignments[
                  newActiveClassroom.active_assignments.findIndex(
                    (activeAssignment) =>
                      activeAssignment.student._id ===
                      displayData.assignment!.student._id
                  )
                ].assignments.findIndex(
                  (studentAssignment) =>
                    studentAssignment.story === assignment.story
                )
              ].teacher_data = { manual_score: Number(text.trim()) });
        }
        setActiveClassroomInContext(newActiveClassroom, true);
        if (notes) {
          setNotes(text.trim());
        } else {
          setScore(Number(text.trim()));
        }
        setUser(
          userData._id && userData._id !== ""
            ? userData._id
            : localStorage.getItem("id")!
        );
      }
      setClicked({ notes: false, manualScore: false });
    }
  };

  return assignment.relevant_data ? (
    view && storyDetails ? (
      <div className="modal__completed--stats-container">
        <div className="modal__completed--main-stats">
          <div className="modal__completed--notes-container">
            <div className="modal__completed--notes-prompt">
              Attach notes to this assignment
            </div>
            {
              <div
                contentEditable
                suppressContentEditableWarning
                onBlur={(e) => SubmitData(e.currentTarget.textContent!, true)}
                className="modal__completed--notes-div"
              >
                {notes}
              </div>
            }
          </div>
          <div className="modal__completed--manual-score-container">
            <div className="modal__completed--manual-score-prompt">Grade</div>

            <div
              contentEditable
              suppressContentEditableWarning
              onBlur={(e) => {
                if (isNaN(Number(e.currentTarget.textContent))) {
                  e.currentTarget.innerHTML = `${score}`;
                } else {
                  SubmitData(e.currentTarget.textContent!, false);
                }
              }}
              className="modal__completed--manual-score-div"
            >
              {score}
            </div>
          </div>
        </div>

        {!window.location.href.includes("markup") && (
          <div
            className="modal__completed--go-back"
            onClick={() => {
              setDonePosting(false);
              setExpandedAssignments((prev) =>
                prev.filter((storyId) => storyId !== assignment.story)
              );
            }}
          >
            Minimize Markup
          </div>
        )}

        <div className="modal__completed--mistakes-header">
          Mistakes and Markup
        </div>

        <div
          className={`modal__completed--manual-markup${
            manualMarkup.isActive ? "--manual" : "--auto"
          }`}
        >
          {manualMarkup.isActive
            ? "Manual Markup Active"
            : "Auto Markup Active"}
          <label className="modal__completed--switch">
            <input
              readOnly
              className="modal__completed--switch-input"
              type="checkbox"
              checked={manualMarkup.isActive}
              onClick={() => {
                setDonePosting(false);
                setManualMarkup((prev: ManualMarkupState) => ({
                  ...prev,
                  isActive: !prev.isActive,
                }));
              }}
            />
            <span className="modal__completed--slider modal__completed--round"></span>
          </label>
        </div>
        <div className="modal__completed--mistake-container">
          {manualMarkup.isActive && (
            <div className="modal__completed--mistake-counter">
              Mispronunciations
              <span
                style={{
                  background: "#9e62e2",
                  color: "white",
                  borderRadius: "4px",
                  padding: "5px 8px",
                  marginLeft: "3px",
                }}
              >
                {
                  manualMarkup.markedUpWords.filter(
                    (markedUpWord) =>
                      markedUpWord.mistake === "Mispronunciation"
                  ).length
                }
              </span>
            </div>
          )}
          <div className="modal__completed--mistake-counter">
            Omissions
            <span
              style={{
                background: "#fb6557",
                color: "white",
                borderRadius: "4px",
                padding: "5px 8px",
                marginLeft: "3px",
              }}
            >
              {manualMarkup.isActive
                ? manualMarkup.markedUpWords.filter(
                    (markedUpWord) => markedUpWord.mistake === "Omission"
                  ).length
                : assignment.relevant_data.missed_words.length}
            </span>
          </div>
          {manualMarkup.isActive && (
            <div className="modal__completed--mistake-counter">
              Repetitions
              <span
                style={{
                  background: "#0093e7",
                  color: "white",
                  borderRadius: "4px",
                  padding: "5px 8px",
                  marginLeft: "3px",
                }}
              >
                {
                  manualMarkup.markedUpWords.filter(
                    (markedUpWord) => markedUpWord.mistake === "Repetition"
                  ).length
                }
              </span>
            </div>
          )}
          {manualMarkup.isActive && (
            <div className="modal__completed--mistake-counter">
              Self-Corrections
              <span
                style={{
                  background: "#ff982a",
                  color: "white",
                  borderRadius: "4px",
                  padding: "5px 8px",
                  marginLeft: "3px",
                }}
              >
                {
                  manualMarkup.markedUpWords.filter(
                    (markedUpWord) => markedUpWord.mistake === "Self-Correction"
                  ).length
                }
              </span>
            </div>
          )}
          <div className="modal__completed--accuracy-counter">
            Accuracy:{" "}
            <span className="modal__completed--accuracy">
              {manualMarkup.isActive
                ? `${
                    Math.round(
                      (10000 *
                        (Array.from(
                          new Set(
                            storyDetails?.pages
                              .map((page) => {
                                return page.page_text
                                  .replace(/\r/g, "")
                                  .split(/\n/)
                                  .join(";;")
                                  .split(";;")
                                  .join(" ")
                                  .split(" ");
                              })
                              .flat()
                          )
                        ).length -
                          manualMarkup.markedUpWords.length)) /
                        Array.from(
                          new Set(
                            storyDetails?.pages
                              .map((page) => {
                                return page.page_text
                                  .replace(/\r/g, "")
                                  .split(/\n/)
                                  .join(";;")
                                  .split(";;")
                                  .join(" ")
                                  .split(" ");
                              })
                              .flat()
                          )
                        ).length
                    ) / 100
                  }%`
                : `${
                    Math.round(assignment.relevant_data.accuracy * 10000) / 100
                  }%`}
            </span>
          </div>
          {manualMarkup.isActive && (
            <div className="modal__completed--mistake-counter">
              Others
              <span
                style={{
                  background: "#209a5d",
                  color: "white",
                  borderRadius: "4px",
                  padding: "5px 8px",
                  marginLeft: "3px",
                }}
              >
                {
                  manualMarkup.markedUpWords.filter(
                    (markedUpWord) => markedUpWord.mistake === "Other"
                  ).length
                }
              </span>
            </div>
          )}
        </div>
        <div
          className="modal__completed--story-text-container"
          tabIndex={0}
          onBlur={async () => {
            setDonePosting(true);

            const request = await fetch(
              `${GetBaseURL()}/api/classrooms/submitMarkup?key=${
                process.env.REACT_APP_PERSONAL_AUTH_KEY
              }`,
              {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  classroom_id: displayData.activeClassroom?._id,
                  student_id: displayData.assignment?.student._id,
                  story_id: assignment.story,
                  markup: manualMarkup.markedUpWords,
                }),
              }
            );
            const response = await request.json();
            if (request.status === 200) {
              let newActiveClassroom = displayData.activeClassroom!;
              if (assignment.teacher_data) {
                newActiveClassroom.active_assignments[
                  newActiveClassroom?.active_assignments.findIndex(
                    (activeAssignment) =>
                      activeAssignment.student._id ===
                      displayData.assignment!.student._id
                  )
                ].assignments[
                  newActiveClassroom.active_assignments[
                    newActiveClassroom.active_assignments.findIndex(
                      (activeAssignment) =>
                        activeAssignment.student._id ===
                        displayData.assignment!.student._id
                    )
                  ].assignments.findIndex(
                    (studentAssignment) =>
                      studentAssignment.story === assignment.story
                  )
                ].teacher_data!.markup = manualMarkup.markedUpWords;
              } else {
                newActiveClassroom.active_assignments[
                  newActiveClassroom?.active_assignments.findIndex(
                    (activeAssignment) =>
                      activeAssignment.student._id ===
                      displayData.assignment!.student._id
                  )
                ].assignments[
                  newActiveClassroom.active_assignments[
                    newActiveClassroom.active_assignments.findIndex(
                      (activeAssignment) =>
                        activeAssignment.student._id ===
                        displayData.assignment!.student._id
                    )
                  ].assignments.findIndex(
                    (studentAssignment) =>
                      studentAssignment.story === assignment.story
                  )
                ].teacher_data = { markup: manualMarkup.markedUpWords };
              }
              setActiveClassroomInContext(newActiveClassroom, true);
            }
          }}
        >
          <div className="modal__completed--audio-container">
            <audio
              className="modal__completed--audio"
              src={`${process.env.REACT_APP_AWS_BUCKET_URL}/${assignment.relevant_data.audio_src}`}
              controls
            />
          </div>
          {storyDetails.pages.map((page, pageIndex) => (
            <div
              className={
                pageIndex === 0
                  ? "modal__completed--story-page modal__completed--story-page--first"
                  : "modal__completed--story-page"
              }
              key={generateID()}
            >
              {page.lines.map((line, lineIndex) => (
                <div className="modal__completed--story-line">
                  {line.split(" ").map((word, wordIndex) => (
                    <React.Fragment
                      key={`${pageIndex}${lineIndex}${wordIndex}`}
                    >
                      <span
                        className="modal__completed--story-word"
                        onClick={() => {
                          setDonePosting(false);

                          setMistakeOptions({
                            show:
                              !manualMarkup.isActive ||
                              (mistakeOptions.show &&
                                mistakeOptions.lineIndex === lineIndex &&
                                mistakeOptions.pageIndex === pageIndex &&
                                mistakeOptions.wordIndex === wordIndex)
                                ? false
                                : true,
                            pageIndex,
                            lineIndex,
                            wordIndex,
                          });
                        }}
                        style={
                          manualMarkup.isActive
                            ? manualMarkup.markedUpWords.some(
                                (markedUpWord) =>
                                  markedUpWord.page_index === pageIndex &&
                                  markedUpWord.line_index === lineIndex &&
                                  markedUpWord.word_index === wordIndex &&
                                  markedUpWord.mistake === "Mispronunciation"
                              )
                              ? {
                                  color: "white",
                                  background: "#9e62e2",
                                }
                              : manualMarkup.markedUpWords.some(
                                  (markedUpWord) =>
                                    markedUpWord.page_index === pageIndex &&
                                    markedUpWord.line_index === lineIndex &&
                                    markedUpWord.word_index === wordIndex &&
                                    markedUpWord.mistake === "Omission"
                                )
                              ? { color: "white", background: "#fb6557" }
                              : manualMarkup.markedUpWords.some(
                                  (markedUpWord) =>
                                    markedUpWord.page_index === pageIndex &&
                                    markedUpWord.line_index === lineIndex &&
                                    markedUpWord.word_index === wordIndex &&
                                    markedUpWord.mistake === "Repetition"
                                )
                              ? { color: "white", background: "#0093e7" }
                              : manualMarkup.markedUpWords.some(
                                  (markedUpWord) =>
                                    markedUpWord.page_index === pageIndex &&
                                    markedUpWord.line_index === lineIndex &&
                                    markedUpWord.word_index === wordIndex &&
                                    markedUpWord.mistake === "Self-Correction"
                                )
                              ? { color: "white", background: "#ff982a" }
                              : manualMarkup.markedUpWords.some(
                                  (markedUpWord) =>
                                    markedUpWord.page_index === pageIndex &&
                                    markedUpWord.line_index === lineIndex &&
                                    markedUpWord.word_index === wordIndex &&
                                    markedUpWord.mistake === "Other"
                                )
                              ? { color: "white", background: "#209a5d" }
                              : undefined
                            : assignment.relevant_data!.missed_words.includes(
                                word
                                  .toLowerCase()
                                  .replace(
                                    /\[|\]|\"|\”|”|\“|“|\:|\;|\(|\)|\.|\,|\?|\!/gi,
                                    ""
                                  )
                              )
                            ? {
                                color: "white",
                                background: "red",
                                pointerEvents: "none",
                              }
                            : undefined
                        }
                      >
                        {word}
                        {mistakeOptions.show &&
                          mistakeOptions.pageIndex === pageIndex &&
                          mistakeOptions.lineIndex === lineIndex &&
                          mistakeOptions.wordIndex === wordIndex && (
                            <MistakeOptionsDropdown
                              manualMarkup={manualMarkup}
                              setManualMarkup={setManualMarkup}
                              setMistakeOptions={setMistakeOptions}
                              pageIndex={pageIndex}
                              lineIndex={lineIndex}
                              wordIndex={wordIndex}
                              word={word}
                            />
                          )}
                      </span>
                      <span> </span>
                    </React.Fragment>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="modal__completed--stat-prompt">Comprehension Score</div>
        <div className="modal__completed--stat">
          {assignment.relevant_data.quiz_score}
        </div>
      </div>
    ) : (
      <div className="modal__completed--stats-container">
        <div className="modal__completed--tags-container">
          <div className="modal__completed--manual-score-container">
            <div className="modal__completed--manual-score-prompt">
              Enter a manual score
            </div>

            <div
              contentEditable
              suppressContentEditableWarning
              onBlur={(e) => {
                if (isNaN(Number(e.currentTarget.textContent))) {
                  e.currentTarget.innerHTML = `${score}`;
                } else {
                  SubmitData(e.currentTarget.textContent!, false);
                }
              }}
              className="modal__completed--manual-score-div"
            >
              {score}
            </div>
          </div>
          <div className="modal__completed--tags-graded">Graded</div>
          <div className="modal__completed--tags-not-graded">Not Graded</div>

          <div className="modal__completed--tags-homework">Homework</div>
          <div className="modal__completed--tags-assessment">Assessment</div>
        </div>
        <div className="modal__completed--main-stats">
          <div className="modal__completed--notes-container">
            <div className="modal__completed--notes-prompt">
              Internal Notes:
            </div>

            <div
              contentEditable
              suppressContentEditableWarning
              onBlur={(e) => SubmitData(e.currentTarget.textContent!, true)}
              className="modal__completed--notes-div"
            >
              {notes}
            </div>
          </div>
          <div className="modal__completed--notes-container">
            <div className="modal__completed--notes-prompt">
              Student Feedback:
            </div>

            <div
              contentEditable
              suppressContentEditableWarning
              onBlur={(e) => SubmitData(e.currentTarget.textContent!, true)}
              className="modal__completed--notes-div"
            >
              {notes}
            </div>
          </div>
          <div className="modal__completed--notes-share">Share with parent</div>
        </div>
        {/* <div className="modal__completed--stat-prompt">
          Recorded Audio and Accuracy
        </div> */}
        {/* <div className="modal__completed--stat-prompt">Comprehension Score</div>
        <div className="modal__completed--stat">
          {assignment.relevant_data.quiz_score}
        </div> */}
        <div className="modal__completed--stat">
          {/* <audio
            className="modal__completed--audio"
            controls
            ref={audioRef}
            onPlay={() =>
              setTimeout(() => {
                setShowDuration(true);
              }, 2000)
            }
          >
            <source
              src={`${process.env.REACT_APP_AWS_BUCKET_URL}/${assignment.relevant_data.audio_src}`}
              type="audio/wav"
            />
          </audio> */}

          {/* {audioRef.current &&
            (audioRef.current.duration === Infinity ||
            isNaN(audioRef.current.duration) ||
            !showDuration ? (
              <div>Play audio to see WPM</div>
            ) : (
              <div>
                {Math.round(
                  Array.from(
                    new Set(
                      storyDetails?.pages
                        .map((page) => {
                          return page.page_text
                            .replace(/\r/g, "")
                            .split(/\n/)
                            .join(";;")
                            .split(";;")
                            .join(" ")
                            .split(" ");
                        })
                        .flat()
                    )
                  ).length /
                    (audioRef.current.duration / 60)
                )}
              </div>
            ))} */}

          {/* <div className="modal__completed--accuracy">
            {Math.round(assignment.relevant_data.accuracy * 10000) / 100}%
          </div> */}
          {/* <div
            className="modal__completed--see-more"
            onClick={() => {
              setDonePosting(false);
              setExpandedAssignments((prev) => [...prev, assignment.story]);
            }}
          >
            See more
          </div> */}
        </div>
      </div>
    )
  ) : (
    <div className="modal__completed--no-data">No Data</div>
  );
};

export default CompletedAssignmentDetails;
